/* Checking Security */
export const CHECKING_SECURITY_START = 'CHECKING_SECURITY_START';
export const CHECKING_SECURITY_SUCCESS = 'CHECKING_SECURITY_SUCCESS';
export const CHECKING_SECURITY_FAIL = 'CHECKING_SECURITY_FAIL';

/* Packages List */
export const GET_PACKAGES_LIST_START = 'GET_PACKAGES_LIST_START';
export const GET_PACKAGES_LIST_SUCCESS = 'GET_PACKAGES_LIST_SUCCESS';
export const GET_PACKAGES_LIST_FAIL = 'GET_PACKAGES_LIST_FAIL';

/* Payment Methods */
export const GET_PAYMENT_METHODS_START = 'GET_PAYMENT_METHODS_START';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';

/* Create Payment */
export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAIL = 'CREATE_PAYMENT_FAIL';

/* Create Saved Payment */
export const CREATE_SAVED_PAYMENT_START = 'CREATE_SAVED_PAYMENT_START';
export const CREATE_SAVED_PAYMENT_FINISH = 'CREATE_SAVED_PAYMENT_FINISH';

/* Create Paypal Payment */
export const CREATE_PAYPAL_PAYMENT_START = 'CREATE_PAYPAL_PAYMENT_START';
export const CREATE_PAYPAL_PAYMENT_SUCCESS = 'CREATE_PAYPAL_PAYMENT_SUCCESS';
export const CREATE_PAYPAL_PAYMENT_FAIL = 'CREATE_PAYPAL_PAYMENT_FAIL';

/* Create iDeal Payment */
export const CREATE_IDEAL_PAYMENT_START = 'CREATE_IDEAL_PAYMENT_START';
export const CREATE_IDEAL_PAYMENT_SUCCESS = 'CREATE_IDEAL_PAYMENT_SUCCESS';
export const CREATE_IDEAL_PAYMENT_FAIL = 'CREATE_IDEAL_PAYMENT_FAIL';

/* Create Sepa Payment */
export const CREATE_SEPA_PAYMENT_START = 'CREATE_SEPA_PAYMENT_START';
export const CREATE_SEPA_PAYMENT_SUCCESS = 'CREATE_SEPA_PAYMENT_SUCCESS';
export const CREATE_SEPA_PAYMENT_FAIL = 'CREATE_SEPA_PAYMENT_FAIL';

/* Create Coinbase Payment */
export const CREATE_COINBASE_PAYMENT_START = 'CREATE_COINBASE_PAYMENT_START';
export const CREATE_COINBASE_PAYMENT_SUCCESS = 'CREATE_COINBASE_PAYMENT_SUCCESS';
export const CREATE_COINBASE_PAYMENT_FAIL = 'CREATE_COINBASE_PAYMENT_FAIL';

/* Create Coinbill Payment */
export const CREATE_COINBILL_PAYMENT_START = 'CREATE_COINBILL_PAYMENT_START';
export const CREATE_COINBILL_PAYMENT_SUCCESS = 'CREATE_COINBILL_PAYMENT_SUCCESS';
export const CREATE_COINBILL_PAYMENT_FAIL = 'CREATE_COINBILLL_PAYMENT_FAIL';

/* Create PaymentWall Payment */
export const CREATE_PAYMENTWALL_PAYMENT_START = 'CREATE_PAYMENTWALL_PAYMENT_START';
export const CREATE_PAYMENTWALL_PAYMENT_SUCCESS = 'CREATE_PAYMENTWALL_PAYMENT_SUCCESS';
export const CREATE_PAYMENTWALL_PAYMENT_FAIL = 'CREATE_PAYMENTWALL_PAYMENT_FAIL';

/* Create Freemimum */
export const CREATE_FREEMIUM_START = 'CREATE_FREEMIUM_START';
export const CREATE_FREEMIUM_SUCCESS = 'CREATE_FREEMIUM_SUCCESS';
export const CREATE_FREEMIUM_FAIL = 'CREATE_FREEMIUM_FAIL';

/* Reactivate Freemimum */
export const REACTIVATE_FREEMIUM_START = 'REACTIVATE_FREEMIUM_START';
export const REACTIVATE_FREEMIUM_SUCCESS = 'REACTIVATE_FREEMIUM_SUCCESS';
export const REACTIVATE_FREEMIUM_FAIL = 'REACTIVATE_FREEMIUM_FAIL';

/* Update Security Details */
export const UPDATE_SECURITY_DETAILS = 'UPDATE_SECURITY_DETAILS';

/* Set Selected Package */
export const SET_SELECTED_PACKAGE = 'SET_SELECTED_PACKAGE';

/* Get Coupon */
export const GET_COUPON_START = 'GET_COUPON_START';
export const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
export const GET_COUPON_FAIL = 'GET_COUPON_FAIL';

/* Get CouponMonthly */
export const GET_COUPON_MONTHLY_START = 'GET_COUPON_MONTHLY_START';
export const GET_COUPON_MONTHLY_SUCCESS = 'GET_COUPON_MONTHLY_SUCCESS';
export const GET_COUPON_MONTHLY_FAIL = 'GET_COUPON_MONTHLY_FAIL';

/* Get CouponYearly2 */
export const GET_COUPON_YEARLY_2_START = 'GET_COUPON_YEARLY_2_START';
export const GET_COUPON_YEARLY_2_SUCCESS = 'GET_COUPON_YEARLY_2_SUCCESS';
export const GET_COUPON_YEARLY_2_FAIL = 'GET_COUPON_YEARLY_2_FAIL';

/* Get Coupon By Code */
export const GET_COUPON_BY_CODE_START = 'GET_COUPON_BY_CODE_START';
export const GET_COUPON_BY_CODE_SUCCESS = 'GET_COUPON_BY_CODE_SUCCESS';
export const GET_COUPON_BY_CODE_FAIL = 'GET_COUPON_BY_CODE_FAIL';
export const RESET_COUPON_BY_CODE = 'RESET_COUPON_BY_CODE';

/* Get Servers Details */
export const GET_SERVERS_DETAILS = 'GET_SERVERS_DETAILS';

/* Get User Auth Info */
export const USER_AUTH_INFO_START = 'USER_AUTH_INFO_START';
export const USER_AUTH_INFO_FINISH = 'USER_AUTH_INFO_FINISH';

/* Get iDeal Bank List */
export const GET_IDEAL_BANK_LIST = 'GET_IDEAL_BANK_LIST';

/* Update Available Currencies */
export const UPDATE_AVAILABLE_CURRENCIES = 'UPDATE_AVAILABLE_CURRENCIES';

/* Update Query Params */
export const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS';

/* Check free recaptcha */
export const CHECK_FREE_RECAPTCHA = 'CHECK_FREE_RECAPTCHA';

/* Load unconditionally captcha */
export const LOAD_UNCONDITIONALLY_RECAPTCHA = 'LOAD_UNCONDITIONALLY_RECAPTCHA';

/* Update Sentry Props */
export const UPDATE_SENTRY_PROPS = 'UPDATE_SENTRY_PROPS';

/* Get Crypto Logos */
export const GET_CRYPTO_LOGOS = 'GET_CRYPTO_LOGOS';