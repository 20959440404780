import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { PlanTypes } from '../../../config/plan-types';
import { CURRENCY } from '../../../config/main';
import { withNamespaces, Trans } from 'react-i18next';
import {
	Box,
	Paper,
	TextField,
	Typography,
	Button,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import utils from '../../../services/utils';
import { connect } from 'react-redux';
import { removeCouponByPlan, updateSentryProps, getCouponByCode } from '../../../store/actions';

const { REACT_APP_CREDIT_CARD, REACT_APP_PAYPAL_FLEXIBLE, REACT_APP_WEB } = process.env;

import { withStyles } from '@material-ui/core/styles';
import { useStyles } from './PaymentInfoV2Styles';

function PaymentInfoV2({
	t,
	i18n,
	_pf,
	_pft,
	_pfe,
	paymentNotEligible = false,
	paymentDisplayName = '',
	planConfig,
	savingPercent,
	saving2Percent,
	saving,
	selectedPackage,
	classes,
	customClassName,
	currency,
	couponPrice,
	couponGracePeriod,
	couponValidPlan,
	couponRenewalPrice,
	couponSavingPercent,
	couponByPlan,
	couponCode,
	couponByCode,
	isCouponCodeFieldOpened,
	couponByCodeIsLoading,
	sentryProps,
	onChangePaymentMethod,
	onChangeCouponCodeField,
	onSubmitCouponCode,
	onOpenCouponCodeField,
	onRemoveCouponByPlan,
	onChangeFormFields,
	onUpdateSentryProps,
	onGetCouponByCode,
}) {
	const _currency = CURRENCY[currency] || currency;
	const _basePrice = (selectedPackage?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
	const _amount = _basePrice?.amount || 0;
	const _renewalPrice = selectedPackage?.recurringPeriod?.renewalPrice || 0;
	const _gracePeriod = selectedPackage?.recurringPeriod?.grace_period_months || 0;
	const _baseSentryPrice = ((selectedPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
	const _baseSentryMonths = Math.floor(((selectedPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.length || 0)/30);
	const _amountSentry = _baseSentryPrice?.amount || 0;
	const _amountSentryTotal = !couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? ((selectedPackage.packageIdentifier === couponValidPlan ? couponGracePeriod : _gracePeriod) || _baseSentryMonths)*_amountSentry : 0;

	const handleRemoveCouponByPlan = () => {
		onRemoveCouponByPlan(couponByPlan?.code);
		if(couponByPlan?.code === couponCode) {
			onChangeCouponCodeField('');
		}
	};

	const handleUpdateSentryProps = () => {
		const _couponByCode = couponByCode.code || couponByCode.prevCode;
		onUpdateSentryProps({
			...sentryProps,
			[selectedPackage.packageIdentifier]: true
		});
		
		if(_couponByCode) {
			onGetCouponByCode(_couponByCode, selectedPackage.packageIdentifier, (selectedPackage.packageIdentifier ? ['SENTRYS'] : []));
		}
	};

	return selectedPackage.packageIdentifier ? (
		<Paper elevation={0} className={clsx(classes.paymentInfoContainer, (customClassName) ? classes[customClassName] : null)}>
			{paymentNotEligible ? 
				<Box mb={2} className={classes.paymentNotEligible}>
					<Trans i18nKey="transPaymentCouponRestriction">
						{{pcrestriction1: paymentDisplayName}} is not eligible for coupon {{pcrestriction2: couponByPlan?.code}}. Select <a onClick={() => onChangePaymentMethod(REACT_APP_CREDIT_CARD)}>Credit Card</a> or <a onClick={() => onChangePaymentMethod(REACT_APP_PAYPAL_FLEXIBLE)}>PayPal</a> to use this coupon, or remove the coupon to use {{pcrestriction3: paymentDisplayName}}.
					</Trans>
				</Box> :
				null
			}
			{selectedPackage.packageIdentifier !== PlanTypes.FREE ?
				<Box display="flex" justify="center" className={classes.paymentInfoLine}>
					<Box className={classes.paymentInfoLabel}><span>{t((selectedPackage.packageIdentifier === PlanTypes.MONTHLY) ? '1 Month Plan Price:' : 'Regular Price:')}</span></Box>
					<Box className={clsx(classes.paymentInfoValue, {[classes.lineThrough]: (selectedPackage.packageIdentifier == PlanTypes.YEARLY || selectedPackage.packageIdentifier == PlanTypes.YEARLY2)})}>{_currency + (selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? (couponValidPlan === PlanTypes.MONTHLY ? +couponPrice : _amount)  : saving.regularPrice).toFixed(2)}</Box>
				</Box>: null
			}
			{(selectedPackage.packageIdentifier === PlanTypes.YEARLY || selectedPackage.packageIdentifier === PlanTypes.YEARLY2) ?
				<Box display="flex" justify="center" className={classes.paymentInfoLine}>
					<Box className={classes.paymentInfoLabel}><span>{t('Discount: ')}</span></Box>
					<Box className={clsx(classes.paymentInfoValue, classes.textGreen)}>{'-' + _currency + saving.discount.toFixed(2)}</Box>
				</Box> : null
			}

			<Box display="flex" justify="center" className={clsx(classes.paymentInfoLine, classes.couponContainer)}>
				<Box className={clsx('couponSectionV2', classes.couponSection)}>
					<Box justify="center" style={{display: (couponByPlan?.code && paymentNotEligible || isCouponCodeFieldOpened ? 'flex' : 'none')}}>
						<TextField
							variant="outlined"
							placeholder={t('Coupon')}
							name="couponCode"
							disabled={couponByPlan?.code && paymentNotEligible}
							value={couponByPlan?.code && paymentNotEligible ? couponByPlan?.code : (couponCode || '')}
							onChange={e => onChangeCouponCodeField(e.target.value)}
						/>
						<Button
							variant="contained"
							color="primary"
							className={classes.couponBtn}
							disabled={!(couponByPlan?.code && paymentNotEligible || !!couponCode) || couponByCodeIsLoading}
							onClick={couponByPlan?.code && paymentNotEligible ? handleRemoveCouponByPlan : onSubmitCouponCode}
						>{t(couponByPlan?.code && paymentNotEligible ? 'Remove' : 'Apply')}</Button>

					</Box>
					<Box className={classes.couponInitBtn} style={{display: (couponByPlan?.code && paymentNotEligible || isCouponCodeFieldOpened ? 'none' : 'block')}} onClick={onOpenCouponCodeField}>
						{couponCode ? t('Coupon Applied') : t('Have a Coupon?')}
					</Box>

					{isCouponCodeFieldOpened && (couponByCode.success || couponByCode.error) ?
						<Box className={classes.couponSectionMessagesBox}>
							<Typography component="p"  className={clsx(classes.couponCodeMessage, {[classes.couponCodeSuccess]: couponByCode.success, [classes.couponCodeError]: couponByCode.error})}>
								{couponByCode.message || t(couponByCode.success ? ((selectedPackage.packageIdentifier !== couponValidPlan || selectedPackage.initialSelectedPackageId) && couponValidPlan === PlanTypes.YEARLY ? 'Coupon code applied on 12 Month Plan.' : (selectedPackage.packageIdentifier !== couponValidPlan ? (couponValidPlan === PlanTypes.YEARLY2 ? 'Coupon code applied on 24 Month Plan.' : 'Coupon code applied on 1 Month Plan.') : 'Coupon code applied.')) : 'Invalid coupon code.')}
							</Typography>
						</Box>: null
					}
				</Box>
				{(selectedPackage.packageIdentifier === PlanTypes.YEARLY2 || selectedPackage.packageIdentifier === PlanTypes.YEARLY) ?
					<span className={classes.paymentInfoSaving}>{t('You Are Saving ') + ' ' + ((selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? saving2Percent : savingPercent) || planConfig.savingPercent || couponSavingPercent)}</span>:
					null
				}
			</Box>

			{planConfig.sentry && _amountSentry ? 
				<>
					<Box display="flex" justifyContent="space-between" className={clsx(classes.paymentInfoBlock, classes.paymentInfoSentry)}>
						{sentryProps[selectedPackage.packageIdentifier] ?
							<Box>{t('Antivirus')}</Box>:
							<Box>{t('Add Antivirus for') + ' (' +  _currency + _amountSentry + t('/mo') + ')'}</Box>
						}
						<Box fontWeight={700}>
							{sentryProps[selectedPackage.packageIdentifier] ? 
								(_currency + _amountSentry): 
								<span className={classes.paymentInfoAddSentry} onClick={handleUpdateSentryProps}>{t('Add')}&nbsp;+</span>
							}
						</Box>
					</Box>
					<Box className={classes.paymentInfoSeparator}/>
				</>:
				null
			}

			<Box display="flex" justifyContent="space-between" >
				<Box className={clsx(classes.paymentInfoLabel, classes.paymentTotal)}>{t('Today\'s Total:')}</Box>
				<Box className={clsx(classes.paymentInfoValue, classes.paymentTotal)}>
					<div>{_currency + (((selectedPackage.packageIdentifier === couponValidPlan) ? (+couponPrice || _amount) : _amount) + _amountSentryTotal).toFixed(2)}</div>
					{selectedPackage.packageIdentifier === PlanTypes.YEARLY && !planConfig.hideYearlyPerMonth && (couponGracePeriod && couponGracePeriod > 1 || !couponGracePeriod) ?
						<div className={classes.fsMd}>{'(' + _currency + (((couponValidPlan === PlanTypes.YEARLY) ? (+couponPrice/(couponGracePeriod || 12)) : (_amount/(_gracePeriod || 12))) + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _amountSentry : 0)).toFixed(2) + t('/mo') + ')'}</div>:
						null
					}
					{selectedPackage.packageIdentifier === PlanTypes.YEARLY2 && (couponGracePeriod && couponGracePeriod > 1 || !couponGracePeriod) ? 
						<div className={classes.fsMd}>{'(' + _currency + ((couponValidPlan === PlanTypes.YEARLY2) ? (+couponPrice/(couponGracePeriod || 24)) : (_amount/(_gracePeriod || 24)) + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _amountSentry : 0)).toFixed(2) + t('/mo') + ')'}</div>:
						null
					}
				</Box>
			</Box>

			<Box className={classes.termsContainer}>
				<FormControlLabel
					classes={{root: classes.termsCheckbox}}
					control={
						<Checkbox
							checked={_pf.termsAndConditions || false}
							onChange={e => onChangeFormFields(e.target.checked, 'termsAndConditions')}
							classes={_pft.termsAndConditions && _pfe.termsAndConditions ? {root: clsx(classes.checkbox, classes.checkboxError)} : {root: classes.checkbox}}
							color="primary"
						/>
					}
					label={(
						<span className={classes.terms}> 
							<Trans i18nKey="transTermsAndConditionsNew">
								I have read and agree to the <a color="textPrimary" href={REACT_APP_WEB + utils.combineRoute((i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage), '/terms-of-service/')} target="_blank" rel="noopener noreferrer" >Terms of Service</a> and <a color="textPrimary" href={REACT_APP_WEB + utils.combineRoute((i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage), '/privacy-policy/')} target="_blank" rel="noopener noreferrer" >Privacy Policy</a>.
							</Trans>
							<br/>
							{paymentDisplayName === 'Cryptocurrency' ? (<span>{t('Purchases with cryptocurrency are not eligible for refund.')} </span>) : null}
							{selectedPackage.packageIdentifier === PlanTypes.YEARLY ?
								(couponValidPlan === PlanTypes.YEARLY) ?
									(couponGracePeriod || couponRenewalPrice) ?
										<Trans i18nKey="transBilledGracePeriodMonths"> 
											Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponPrice + _amountSentryTotal).toFixed(2)}} for the first {{transGracePeriod: couponGracePeriod || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
										</Trans>:
										<Trans i18nKey="transBilledEvery12MonthsAlways">
											Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
										</Trans>: 
									(_gracePeriod || _renewalPrice) ?
										<Trans i18nKey="transBilledGracePeriodMonths">
											Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount + _amountSentryTotal).toFixed(2)}} for the first {{transGracePeriod: _gracePeriod || 12}} months, then {{transBilledEvery12Months2: (CURRENCY[currency] || currency) + (+_renewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
										</Trans> : 
										<Trans i18nKey="transBilledEvery12MonthsAlways">
											Billed {{transBilledEvery12Months1: (CURRENCY[currency] || currency) + (+_amount + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 12 months.
										</Trans> :
								null
							}

							{selectedPackage.packageIdentifier === PlanTypes.YEARLY2 ? 
								(couponValidPlan === PlanTypes.YEARLY2) ?
									(couponGracePeriod || couponRenewalPrice) ?
										<Trans i18nKey="transBilled24GracePeriodMonths">
											Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponPrice + _amountSentryTotal).toFixed(2)}} for the first {{trans24GracePeriod: couponGracePeriod || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
										</Trans>:
										<Trans i18nKey="transBilled24Months">
											Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+couponRenewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
										</Trans>:
									(_gracePeriod || _renewalPrice) ?
										<Trans i18nKey="transBilled24GracePeriodMonths">
											Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount + _amountSentryTotal).toFixed(2)}} for the first {{trans24GracePeriod: _gracePeriod || 24}} months, then {{transBilledEvery24Months2: (CURRENCY[currency] || currency) + (+_renewalPrice + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
										</Trans> : 
										<Trans i18nKey="transBilled24Months">
											Billed {{transBilledEvery24Months1: (CURRENCY[currency] || currency) + (+_amount + (!couponByCode?.includeSentry && sentryProps[selectedPackage.packageIdentifier] ? _baseSentryMonths*_amountSentry : 0)).toFixed(2)}} every 24 months.
										</Trans> :
								null
							}

							{selectedPackage.packageIdentifier === PlanTypes.MONTHLY ? 
								<>
									<Trans i18nKey="transBilledEveryMonth">
										Billed {{transBilledEveryMonth: (_currency + (((selectedPackage.packageIdentifier === couponValidPlan) ? (+couponPrice || _amount) : _amount) + _amountSentryTotal).toFixed(2))}} every month.
									</Trans>&nbsp;
									{t('Monthly plans are not eligible for the 30-day money back guarantee.')}
								</> :
								null
							}

						</span>
					)}
				/>
				{_pft.termsAndConditions && _pfe.termsAndConditions ?
					<Typography component="p" className={classes.errorText}>
						<span className={classes.errorIcon}>{_pfe.termsAndConditions}</span>
					</Typography> : null
				}
			</Box>
			{paymentDisplayName === 'Coinbase' || paymentDisplayName === 'Coinbill' ?
				<Box mt={2} className={classes.paymentNotEligible}>
					{t('Purchases with cryptocurrency are not eligible for refund.')}
				</Box>: null
			}
		</Paper>
	) : null;
}

PaymentInfoV2.propTypes = {
	paymentDisplayName: PropTypes.string,
	paymentNotEligible: PropTypes.bool,
	_pf: PropTypes.object,
	_pft: PropTypes.object,
	_pfe: PropTypes.object,
	i18n: PropTypes.object,
	couponCode: PropTypes.string,
	couponByCode: PropTypes.object,
	couponByCodeIsLoading: PropTypes.bool,
	couponByPlan: PropTypes.object,
	isCouponCodeFieldOpened: PropTypes.bool,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	couponSavingPercent: PropTypes.string,
	planConfig: PropTypes.object,
	customClassName: PropTypes.string,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	selectedPackage: PropTypes.object,
	classes: PropTypes.object,
	sentryProps: PropTypes.object,
	onChangePaymentMethod: PropTypes.func,
	onChangeCouponCodeField: PropTypes.func,
	onSubmitCouponCode: PropTypes.func,
	onOpenCouponCodeField: PropTypes.func,
	t: PropTypes.func,
	onRemoveCouponByPlan: PropTypes.func,
	onChangeFormFields: PropTypes.func,
	onUpdateSentryProps: PropTypes.func,
	onGetCouponByCode: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		coupon: state.home.coupon,
		couponByCode: state.home.couponByCode,
		couponByCodeIsLoading: state.home.couponByCodeIsLoading,
		couponMonthly: state.home.couponMonthly,
		couponYearly2: state.home.couponYearly2,
		sentryProps: state.home.sentryProps,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onRemoveCouponByPlan: dataToSend => dispatch(removeCouponByPlan(dataToSend)),
		onUpdateSentryProps: sentryProps => dispatch(updateSentryProps(sentryProps)),
		onGetCouponByCode: (coupon, plan, addons) => dispatch(getCouponByCode(coupon, plan, addons)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(withNamespaces()(PaymentInfoV2)));