import {
	CHECKING_SECURITY_START,
	CHECKING_SECURITY_SUCCESS,
	CHECKING_SECURITY_FAIL,

	GET_PACKAGES_LIST_START,
	GET_PACKAGES_LIST_SUCCESS,
	GET_PACKAGES_LIST_FAIL,

	GET_PAYMENT_METHODS_START,
	GET_PAYMENT_METHODS_SUCCESS,
	GET_PAYMENT_METHODS_FAIL,

	CREATE_SAVED_PAYMENT_START,
	CREATE_SAVED_PAYMENT_FINISH,

	CREATE_PAYMENT_START,
	CREATE_PAYMENT_SUCCESS,
	CREATE_PAYMENT_FAIL,

	CREATE_IDEAL_PAYMENT_START,
	CREATE_IDEAL_PAYMENT_SUCCESS,
	CREATE_IDEAL_PAYMENT_FAIL,

	CREATE_SEPA_PAYMENT_START,
	CREATE_SEPA_PAYMENT_SUCCESS,
	CREATE_SEPA_PAYMENT_FAIL,

	CREATE_COINBASE_PAYMENT_START,
	CREATE_COINBASE_PAYMENT_SUCCESS,
	CREATE_COINBASE_PAYMENT_FAIL,

	CREATE_COINBILL_PAYMENT_START,
	CREATE_COINBILL_PAYMENT_SUCCESS,
	CREATE_COINBILL_PAYMENT_FAIL,

	CREATE_PAYMENTWALL_PAYMENT_START,
	CREATE_PAYMENTWALL_PAYMENT_SUCCESS,
	CREATE_PAYMENTWALL_PAYMENT_FAIL,

	CREATE_PAYPAL_PAYMENT_START,
	CREATE_PAYPAL_PAYMENT_SUCCESS,
	CREATE_PAYPAL_PAYMENT_FAIL,

	CREATE_FREEMIUM_START,
	CREATE_FREEMIUM_SUCCESS,
	CREATE_FREEMIUM_FAIL,

	UPDATE_SECURITY_DETAILS,

	SET_SELECTED_PACKAGE,

	GET_COUPON_START,
	GET_COUPON_SUCCESS,
	GET_COUPON_FAIL,

	GET_COUPON_MONTHLY_START,
	GET_COUPON_MONTHLY_SUCCESS,
	GET_COUPON_MONTHLY_FAIL,

	GET_COUPON_YEARLY_2_START,
	GET_COUPON_YEARLY_2_SUCCESS,
	GET_COUPON_YEARLY_2_FAIL,

	GET_COUPON_BY_CODE_START,
	GET_COUPON_BY_CODE_SUCCESS,
	GET_COUPON_BY_CODE_FAIL,
	RESET_COUPON_BY_CODE,

	GET_SERVERS_DETAILS,

	REACTIVATE_FREEMIUM_START,
	REACTIVATE_FREEMIUM_SUCCESS,
	REACTIVATE_FREEMIUM_FAIL,

	USER_AUTH_INFO_START,
	USER_AUTH_INFO_FINISH,

	GET_IDEAL_BANK_LIST,

	UPDATE_AVAILABLE_CURRENCIES,

	UPDATE_QUERY_PARAMS,
	CHECK_FREE_RECAPTCHA,
	LOAD_UNCONDITIONALLY_RECAPTCHA,
	UPDATE_SENTRY_PROPS,

	GET_CRYPTO_LOGOS
} from '../actions/actionTypes';
import utils from '../../services/utils';

const initialState = {
	securityDetails: {is_blocked: false, is_in_european_union: false, iso_code: '', current_currency: 'USD'},
	checkingSecurity: true,
	packagesList: [],
	loading: true,
	paymentMethods: [],
	paymentMethodsLoading: false,
	createSavedPaymentLoading: false,
	payment: {},
	createPaymentLoading: false,
	idealPayment: {},
	createIdealPaymentLoading: false,
	sepaPayment: {},
	createSepaPaymentLoading: false,
	coinbasePayment: {},
	createCoinbasePaymentLoading: false,
	coinbillPayment: {},
	createCoinbillPaymentLoading: false,
	paymentWall: {},
	createPaymentWallLoading: false,
	selectedPackage: null,
	paypalPayment: {},
	createPaypalPaymentIsLoading: false,
	createFreemiumLoading: false,
	createFreemiumState: {success: false, email: ''},
	coupon: {},
	couponIsLoading: false,
	couponByCode: {},
	couponByCodeIsLoading: false,
	couponMonthly: {},
	couponMonthlyIsLoading: false,
	couponYearly2: {},
	couponYearly2IsLoading: false,
	serversDetails: {},
	reactivateFreemiumLoading: false,
	userAuthInfoLoading: false,
	iDealBankList: [],
	allowedCountries: [],
	availableCurrencies: [],
	queryParams: {},
	freeRecaptchaChecked: false,
	loadUnconditionallyCaptchaKey: false,
	sentryProps: {},
	cryptoLogos: []
};

/* check security start */
const checkSecurityStart = (state) => {
	return utils.updateObject(state, {
		checkingSecurity: true
	});
};

const checkSecuritySuccess = (state, action) => {
	return utils.updateObject(state, {
		securityDetails: action.securityDetails,
		checkingSecurity: false
	});
};

const checkSecurityFail = (state) => {
	return utils.updateObject(state, {
		checkingSecurity: false,
		loading: false
	});
};
/* check security end */

/* packages list start */
const getPackagesListStart = (state) => {
	return utils.updateObject(state, {
		packagesList: [],
		loading: true
	});
};

const getPackagesListSuccess = (state, action) => {
	return utils.updateObject(state, {
		packagesList: action.packagesList,
		loading: false
	});
};

const getPackagesListFail = (state) => {
	return utils.updateObject(state, {
		packagesList: [],
		loading: false
	});
};
/* packages list end */

/* payment methods start */
const getPaymentMethodsStart = (state) => {
	return utils.updateObject(state, {
		paymentMethodsLoading: true
	});
};

const getPaymentMethodsSuccess = (state, action) => {
	return utils.updateObject(state, {
		paymentMethods: action.paymentMethods,
		allowedCountries: action.allowedCountries,
		paymentMethodsLoading: false
	});
};

const getPaymentMethodsFail = (state) => {
	return utils.updateObject(state, {
		paymentMethods: [],
		paymentMethodsLoading: false
	});
};
/* payment methods end */

/* create payment start */
const createSavedPaymentStart = (state) => {
	return utils.updateObject(state, {
		createSavedPaymentLoading: true
	});
};

const createSavedPaymentFinish = (state) => {
	return utils.updateObject(state, {
		createSavedPaymentLoading: false
	});
};
/* create payment end */

/* create payment start */
const createPaymentStart = (state) => {
	return utils.updateObject(state, {
		payment: {},
		createPaymentLoading: true
	});
};

const createPaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		payment: action.payment,
		createPaymentLoading: true
	});
};

const createPaymentFail = (state) => {
	return utils.updateObject(state, {
		payment: {},
		createPaymentLoading: false
	});
};
/* create payment end */

/* create iDeal payment start */
const createIdealPaymentStart = (state) => {
	return utils.updateObject(state, {
		idealPayment: {},
		createIdealPaymentLoading: true
	});
};

const createIdealPaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		idealPayment: action.payment,
		createIdealPaymentLoading: true
	});
};

const createIdealPaymentFail = (state) => {
	return utils.updateObject(state, {
		idealPayment: {},
		createIdealPaymentLoading: false
	});
};
/* create iDeal payment end */

/* create Sepa payment start */
const createSepaPaymentStart = (state) => {
	return utils.updateObject(state, {
		sepaPayment: {},
		createSepaPaymentLoading: true
	});
};

const createSepaPaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		sepaPayment: action.payment,
		createSepaPaymentLoading: true
	});
};

const createSepaPaymentFail = (state) => {
	return utils.updateObject(state, {
		sepaPayment: {},
		createSepaPaymentLoading: false
	});
};
/* create Sepa payment end */

/* create Coinbase payment start */
const createCoinbasePaymentStart = (state) => {
	return utils.updateObject(state, {
		coinbasePayment: {},
		createCoinbasePaymentLoading: true
	});
};

const createCoinbasePaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		coinbasePayment: action.payment,
		createCoinbasePaymentLoading: true
	});
};

const createCoinbasePaymentFail = (state) => {
	return utils.updateObject(state, {
		coinbasePayment: {},
		createCoinbasePaymentLoading: false
	});
};
/* create Coinbase payment end */

/* create Coinbill payment start */
const createCoinbillPaymentStart = (state) => {
	return utils.updateObject(state, {
		coinbillPayment: {},
		createCoinbillPaymentLoading: true
	});
};

const createCoinbillPaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		coinbillPayment: action.payment,
		createCoinbillPaymentLoading: true
	});
};

const createCoinbillPaymentFail = (state) => {
	return utils.updateObject(state, {
		coinbillPayment: {},
		createCoinbillPaymentLoading: false
	});
};
/* create Coinbill payment end */

/* create PaymentWall payment start */
const createPaymentWallStart = (state) => {
	return utils.updateObject(state, {
		paymentWall: {},
		createPaymentWallLoading: true
	});
};

const createPaymentWallSuccess = (state, action) => {
	return utils.updateObject(state, {
		paymentWall: action.payment,
		createPaymentWallLoading: true
	});
};

const createPaymentWallFail = (state) => {
	return utils.updateObject(state, {
		paymentWall: {},
		createPaymentWallLoading: false
	});
};
/* create PaymentWall payment end */

/* create Paypal payment start */
const createPaypalPaymentStart = (state) => {
	return utils.updateObject(state, {
		paypalPayment: {},
		createPaypalPaymentIsLoading: true
	});
};

const createPaypalPaymentSuccess = (state, action) => {
	return utils.updateObject(state, {
		paypalPayment: action.paypalPayment,
		createPaypalPaymentIsLoading: true
	});
};

const createPaypalPaymentFail = (state) => {
	return utils.updateObject(state, {
		paypalPayment: {},
		createPaypalPaymentIsLoading: false
	});
};
/* create Paypal payment end */

/* create freemium start */
const createFreemiumStart = (state) => {
	return utils.updateObject(state, {
		createFreemiumLoading: true
	});
};

const createFreemiumSuccess = (state, action) => {
	return utils.updateObject(state, {
		createFreemiumState: action.createFreemiumState,
		createFreemiumLoading: false
	});
};

const createFreemiumFail = (state) => {
	return utils.updateObject(state, {
		createFreemiumState: {success: false, email: ''},
		createFreemiumLoading: false
	});
};
/* create freemium end */

/* reactivate freemium start */
const reactivateFreemiumStart = (state) => {
	return utils.updateObject(state, {
		reactivateFreemiumLoading: true
	});
};

const reactivateFreemiumSuccess = (state) => {
	return utils.updateObject(state, {
		reactivateFreemiumLoading: true
	});
};

const reactivateFreemiumFail = (state) => {
	return utils.updateObject(state, {
		reactivateFreemiumLoading: false
	});
};
/* reactivate freemium end */

/* set selected package */
const setSelectedPackage = (state, action) => {
	return utils.updateObject(state, {
		selectedPackage: action.selectedPackage
	});
};
/* set selected package end */

/* change securityDetails */
export const updateSecurityDetails= (state, action) => {
	return utils.updateObject(state, {
		securityDetails: action.securityDetails
	});
};
/* change securityDetails end */

/* Get Coupon start */
const getCouponStart = (state) => {
	return utils.updateObject(state, {
		coupon: {},
		couponIsLoading: true
	});
};

const getCouponSuccess = (state, action) => {
	return utils.updateObject(state, {
		coupon: action.coupon,
		couponIsLoading: false
	});
};

const getCouponFail = (state) => {
	return utils.updateObject(state, {
		coupon: {},
		couponIsLoading: false
	});
};
/* Get Coupon end */

/* Get CouponMonthly start */
const getCouponMonthlyStart = (state) => {
	return utils.updateObject(state, {
		couponMonthly: {},
		couponMonthlyIsLoading: true
	});
};

const getCouponMonthlySuccess = (state, action) => {
	return utils.updateObject(state, {
		couponMonthly: action.couponMonthly,
		couponMonthlyIsLoading: false
	});
};

const getCouponMonthlyFail = (state) => {
	return utils.updateObject(state, {
		couponMonthly: {},
		couponMonthlyIsLoading: false
	});
};
/* Get CouponMonthly end */

/* Get CouponYearly2 start */
const getCouponYearly2Start = (state) => {
	return utils.updateObject(state, {
		couponYearly2: {},
		couponYearly2IsLoading: true
	});
};

const getCouponYearly2Success = (state, action) => {
	return utils.updateObject(state, {
		couponYearly2: action.couponYearly2,
		couponYearly2IsLoading: false
	});
};

const getCouponYearly2Fail = (state) => {
	return utils.updateObject(state, {
		couponYearly2: {},
		couponYearly2IsLoading: false
	});
};
/* Get CouponYearly2 end */

/* Get Coupon By Code start */
const getCouponByCodeStart = (state) => {
	return utils.updateObject(state, {
		couponByCodeIsLoading: true
	});
};

const getCouponByCodeSuccess = (state, action) => {
	return utils.updateObject(state, {
		couponByCode: action.couponByCode,
		couponByCodeIsLoading: false
	});
};

const getCouponByCodeFail = (state, action) => {
	return utils.updateObject(state, {
		couponByCode: action.couponByCode,
		couponByCodeIsLoading: false
	});
};
/* Get Coupon By Code end */

/* Reset Coupon By Code */
const resetCoupon= (state, action) => {
	return utils.updateObject(state, {
		couponByCode: action.couponByCode
	});
};
/* Reset Coupon By Code end */

/* Get Servers Details */
const updateServersDetails = (state, action) => {
	return utils.updateObject(state, {
		serversDetails: action.serversDetails
	});
};
/* Get Servers Details end */

/* Get User Auth Info start */
const getUserAuthInfoStart = (state) => {
	return utils.updateObject(state, {
		userAuthInfoLoading: true
	});
};

const getUserAuthInfoFinish = (state) => {
	return utils.updateObject(state, {
		userAuthInfoLoading: false
	});
};
/* Get User Auth Info end */

/* Get iDeal Bank List */
const updateIDealBankList = (state, action) => {
	return utils.updateObject(state, {
		iDealBankList: action.iDealBankList
	});
};
/* Get iDeal Bank List end */

/* update availableCurrencies */
export const updateAvailableCurrencies = (state, action) => {
	return utils.updateObject(state, {
		availableCurrencies: action.availableCurrencies
	});
};
/* update availableCurrencies end */

/* update queryParams */
export const updateQueryParams = (state, action) => {
	return utils.updateObject(state, {
		queryParams: action.queryParams
	});
};
/* update queryParams end */

/* check free recaptcha */
const checkFreeRecaptcha = (state) => {
	return utils.updateObject(state, {
		freeRecaptchaChecked: true
	});
};

/* load unconditionally recaptcha key */
const loadUnconditionallyCaptchaKey = (state) => {
	return utils.updateObject(state, {
		freeRecaptchaChecked: false,
		createFreemiumLoading: true,
		loadUnconditionallyCaptchaKey: true
	});
};

/* update Sentry Props */
export const updateSentryProps = (state, action) => {
	return utils.updateObject(state, {
		sentryProps: action.sentryProps
	});
};
/* update Sentry Props end */


/* Get Crypto Logos */
const updateCryptoLogos = (state, action) => {
	return utils.updateObject(state, {
		cryptoLogos: action.cryptoLogos
	});
};
/* Get Crypto Logos end */

const homeReducer = (state = initialState, action = {}) => {
	switch (action.type) {
	case CHECKING_SECURITY_START: return checkSecurityStart(state, action);
	case CHECKING_SECURITY_SUCCESS: return checkSecuritySuccess(state, action);
	case CHECKING_SECURITY_FAIL: return checkSecurityFail(state, action);

	case GET_PACKAGES_LIST_START: return getPackagesListStart(state, action);
	case GET_PACKAGES_LIST_SUCCESS: return getPackagesListSuccess(state, action);
	case GET_PACKAGES_LIST_FAIL: return getPackagesListFail(state, action);

	case GET_PAYMENT_METHODS_START: return getPaymentMethodsStart(state, action);
	case GET_PAYMENT_METHODS_SUCCESS: return getPaymentMethodsSuccess(state, action);
	case GET_PAYMENT_METHODS_FAIL: return getPaymentMethodsFail(state, action);

	case CREATE_SAVED_PAYMENT_START: return createSavedPaymentStart(state, action);
	case CREATE_SAVED_PAYMENT_FINISH: return createSavedPaymentFinish(state, action);

	case CREATE_PAYMENT_START: return createPaymentStart(state, action);
	case CREATE_PAYMENT_SUCCESS: return createPaymentSuccess(state, action);
	case CREATE_PAYMENT_FAIL: return createPaymentFail(state, action);

	case CREATE_IDEAL_PAYMENT_START: return createIdealPaymentStart(state, action);
	case CREATE_IDEAL_PAYMENT_SUCCESS: return createIdealPaymentSuccess(state, action);
	case CREATE_IDEAL_PAYMENT_FAIL: return createIdealPaymentFail(state, action);

	case CREATE_SEPA_PAYMENT_START: return createSepaPaymentStart(state, action);
	case CREATE_SEPA_PAYMENT_SUCCESS: return createSepaPaymentSuccess(state, action);
	case CREATE_SEPA_PAYMENT_FAIL: return createSepaPaymentFail(state, action);

	case CREATE_COINBASE_PAYMENT_START: return createCoinbasePaymentStart(state, action);
	case CREATE_COINBASE_PAYMENT_SUCCESS: return createCoinbasePaymentSuccess(state, action);
	case CREATE_COINBASE_PAYMENT_FAIL: return createCoinbasePaymentFail(state, action);

	case CREATE_COINBILL_PAYMENT_START: return createCoinbillPaymentStart(state, action);
	case CREATE_COINBILL_PAYMENT_SUCCESS: return createCoinbillPaymentSuccess(state, action);
	case CREATE_COINBILL_PAYMENT_FAIL: return createCoinbillPaymentFail(state, action);

	case CREATE_PAYMENTWALL_PAYMENT_START: return createPaymentWallStart(state, action);
	case CREATE_PAYMENTWALL_PAYMENT_SUCCESS: return createPaymentWallSuccess(state, action);
	case CREATE_PAYMENTWALL_PAYMENT_FAIL: return createPaymentWallFail(state, action);

	case CREATE_PAYPAL_PAYMENT_START: return createPaypalPaymentStart(state, action);
	case CREATE_PAYPAL_PAYMENT_SUCCESS: return createPaypalPaymentSuccess(state, action);
	case CREATE_PAYPAL_PAYMENT_FAIL: return createPaypalPaymentFail(state, action);

	case CREATE_FREEMIUM_START: return createFreemiumStart(state, action);
	case CREATE_FREEMIUM_SUCCESS: return createFreemiumSuccess(state, action);
	case CREATE_FREEMIUM_FAIL: return createFreemiumFail(state, action);

	case REACTIVATE_FREEMIUM_START: return reactivateFreemiumStart(state, action);
	case REACTIVATE_FREEMIUM_SUCCESS: return reactivateFreemiumSuccess(state, action);
	case REACTIVATE_FREEMIUM_FAIL: return reactivateFreemiumFail(state, action);

	case UPDATE_SECURITY_DETAILS: return updateSecurityDetails(state, action);

	case SET_SELECTED_PACKAGE: return setSelectedPackage(state, action);

	case GET_COUPON_START: return getCouponStart(state, action);
	case GET_COUPON_SUCCESS: return getCouponSuccess(state, action);
	case GET_COUPON_FAIL: return getCouponFail(state, action);

	case GET_COUPON_MONTHLY_START: return getCouponMonthlyStart(state, action);
	case GET_COUPON_MONTHLY_SUCCESS: return getCouponMonthlySuccess(state, action);
	case GET_COUPON_MONTHLY_FAIL: return getCouponMonthlyFail(state, action);

	case GET_COUPON_YEARLY_2_START: return getCouponYearly2Start(state, action);
	case GET_COUPON_YEARLY_2_SUCCESS: return getCouponYearly2Success(state, action);
	case GET_COUPON_YEARLY_2_FAIL: return getCouponYearly2Fail(state, action);

	case GET_COUPON_BY_CODE_START: return getCouponByCodeStart(state, action);
	case GET_COUPON_BY_CODE_SUCCESS: return getCouponByCodeSuccess(state, action);
	case GET_COUPON_BY_CODE_FAIL: return getCouponByCodeFail(state, action);
	case RESET_COUPON_BY_CODE: return resetCoupon(state, action);

	case GET_SERVERS_DETAILS: return updateServersDetails(state, action);

	case USER_AUTH_INFO_START: return getUserAuthInfoStart(state, action);
	case USER_AUTH_INFO_FINISH: return getUserAuthInfoFinish(state, action);

	case GET_IDEAL_BANK_LIST: return updateIDealBankList(state, action);

	case UPDATE_AVAILABLE_CURRENCIES: return updateAvailableCurrencies(state, action);

	case UPDATE_QUERY_PARAMS: return updateQueryParams(state, action);
	case CHECK_FREE_RECAPTCHA: return checkFreeRecaptcha(state);
	case LOAD_UNCONDITIONALLY_RECAPTCHA: return loadUnconditionallyCaptchaKey(state);
	case UPDATE_SENTRY_PROPS: return updateSentryProps(state, action);

	case GET_CRYPTO_LOGOS: return updateCryptoLogos(state, action);

	default:
		return state;
	}
};

export default homeReducer;