import React, { Component, Fragment } from 'react';
import clsx  from 'clsx';
import PropTypes from 'prop-types';
import { withNamespaces, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import {
	createSavedPayment,
	createPayment,
	createIdealPayment,
	createSepaPayment,
	createCoinbasePayment,
	createCoinbillPayment,
	createPaymentWall,
	checkEmail,
	checkOrder,
	userAuth,
	processTokenize,
	createPaypalPayment,
	createFreemium,
	changeCountry,
	getIP,
	sendAbandonEmailRequest,
	getCouponByCode,
	resetCouponByCode,
	getPaymentMethods,
	getCurrency,
	captchaIsProcessing,
	updateQueryParams,
	updateSentryProps,
	reactivateFreemium
} from '../../../store/actions';
import { changeCurrency } from '../../../store/reducers/currencySlice';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import customService from '../../../services/custom';
import { PlanTypes } from '../../../config/plan-types';
import { EMAIL_REGEXP, DATE_REGEXP, LETTERS_REGEXP, FIELD_CHECK_FOR_EMAIL_REGEXP } from '../../../config/main';
import moment from 'moment';
import utils from '../../../services/utils';
import {
	TextField,
	Grid,
	Typography,
	Container,
	Button,
	InputAdornment,
	Box
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { transliterate as tr } from 'transliteration';

import Logos from '../../UI/Logos/Logos';
import CreditCardFormV2 from '../FormsV2/CreditCardFormV2';
import PayPalFormV2 from '../FormsV2/PayPalFormV2';
import SepaDirectDebitFormV2 from '../FormsV2/SepaDirectDebitFormV2';
import IDealFormV2 from '../FormsV2/IDealFormV2';
import CoinbaseV2 from '../FormsV2/CoinbaseV2';
import CoinbillV2 from '../FormsV2/CoinbillV2';
import PaymentWallV2 from '../FormsV2/PaymentWallV2';
import SavedPaymentV2 from '../FormsV2/SavedPaymentV2';
import FreeRecaptcha from '../../FreeRecaptcha/FreeRecaptcha';
import LoaderSmall from '../../UI/LoaderSmall/LoaderSmall';
import { useStyles } from './PaymentSectionV2Styles';
import { paymentWallIconStyles } from '../../UI/PaymentWallIcons/PaymentWallIconsStyles';

const { 
	REACT_APP_CREDIT_CARD, 
	REACT_APP_PAYPAL_FLEXIBLE, 
	REACT_APP_SEPA_DIRECT_DEBIT, 
	REACT_APP_IDEAL, 
	REACT_APP_COINBASE, 
	REACT_APP_COINBILL,
	REACT_APP_PAYMENTWALL,
	REACT_APP_SAVED_PAYMENT,
	REACT_APP_WEB, 
	REACT_APP_URL,
	REACT_APP_RECAPTCHA_SRC,
	REACT_APP_COINBILL_URL
} = process.env;
const cookies = new Cookies();

class PaymentSectionV2 extends Component {
	constructor(props) {
		super(props);
		this.abandonTimer = null;
		this.recaptchaWindow = null;
		this.handleProcessAbandonmentEmail = this.handleProcessAbandonmentEmail.bind(this);
		this.checkValidationErrors = this.checkValidationErrors.bind(this);
		this.handleChangeAccountInfo = this.handleChangeAccountInfo.bind(this);
		this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
		this.handleChangePaymentFields = this.handleChangePaymentFields.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handlePaymentFieldsInit = this.handlePaymentFieldsInit.bind(this);
		this.handleCreateFreemium = this.handleCreateFreemium.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleChangeCouponCodeField = this.handleChangeCouponCodeField.bind(this);
		this.handleSubmitCouponCode = this.handleSubmitCouponCode.bind(this);
		this.handleOpenCouponCodeField = this.handleOpenCouponCodeField.bind(this);
		this.captchaBgClickListener = this.captchaBgClickListener.bind(this);
		this.handleCaptchaLoad = this.handleCaptchaLoad.bind(this);
		this.handleChangeCountry = this.handleChangeCountry.bind(this);

		this._isMounted = false;
		this.maskEmailField = null;
		this.emailField = null;
		this.recaptchaRef = React.createRef();
		this.recaptchaFreeRef = React.createRef();
		this.recaptchaIsLoaded = false;
		this.recaptchaFreeIsLoaded = false;

		const {securityDetails: {iso_code}} = this.props;

		this.state = {
			formFields: {
				email: '',
				password: '',
				selectedPaymentMethod: ''
			},
			paymentFields: {},

			fieldsError: {formFields: {}},
			fieldsTouched: {formFields: {}},

			country: iso_code || '',
			btnDisabled: true,
			emailCheckErrorText: '',
			emailSuccess: false,
			withPassword: null,
			authCheckErrorText: '',
			authCheckLoading: false,
			authSuccess: false,
			order_id: '',
			returning_customer: undefined,
			isCouponCodeFieldOpened: false,
			couponCode: '',
			isLoggingIn: false
		};
	}

	componentDidMount() {
		const {formFields: {selectedPaymentMethod, email}} = this.state;
		const {queryParams, enableAbandonmentModal, onOpenAbandonmentModal, selectedPackage, paymentMethods, onCheckOrder} = this.props;
		const _packageId = selectedPackage && selectedPackage.packageIdentifier || '';
		const _email = queryParams.email || sessionStorage?.getItem('email') || '';

		this.maskEmailField = document.getElementById('maskEmailField');
		this.emailField = document.getElementById('email');
		this._isMounted = true;
		if(_packageId) {
			if(!sessionStorage?.getItem('PV_token')) {
				if(_email) {
					this.handleChangeAccountInfo(_email, 'email', selectedPackage, queryParams);
					this.handleBlur('email');
				} else if(queryParams.id) {
					onCheckOrder({order_id: queryParams.id})
						.then(response => {
							if(response.data && response.data.email) {
								this.handleChangeAccountInfo((response.data.email), 'email', selectedPackage);
								this.handleBlur('email');
							}
						});
				}
			}
			if(queryParams.access_token && _email && !email) {
				this.handleChangeAccountInfo((_email), 'email', selectedPackage, queryParams);
			}
		}
		
		if(enableAbandonmentModal) {
			const abandonTimer = utils.idleTimeTicker;
			this.abandonTimer = new abandonTimer(onOpenAbandonmentModal, this.handleProcessAbandonmentEmail);
			this.abandonTimer.init();
		}
		if(!selectedPaymentMethod && paymentMethods.length) {
			this.handleChangePaymentMethod(paymentMethods[0].name);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		if(this.abandonTimer) {
			this.abandonTimer.reset();
		}
		if(this.recaptchaWindow) {
			this.recaptchaWindow?.firstChild?.removeEventListener('click', this.captchaBgClickListener);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {formFields: {selectedPaymentMethod}} = this.state;
		const { queryParams, paymentMethods } = this.props;
		const _nextPackageId = nextProps?.selectedPackage?.packageIdentifier || '';
		const _nextQueryParams = nextProps.queryParams || {};

		if(_nextQueryParams.email && _nextQueryParams.email !== queryParams.email) {
			this.handleChangeAccountInfo(_nextQueryParams.email, 'email', nextProps.selectedPackage, _nextQueryParams);
			this.handleBlur('email');
		}
		
		if(nextProps.paymentMethods.length && (_nextPackageId !== 'FREE') && (!selectedPaymentMethod || (selectedPaymentMethod && (nextProps.paymentMethods.map(p => p.name)).indexOf(selectedPaymentMethod) === -1) || (paymentMethods[0] && paymentMethods[0].name !== REACT_APP_SAVED_PAYMENT && nextProps.paymentMethods[0].name === REACT_APP_SAVED_PAYMENT))) {
			this.handleChangePaymentMethod(nextProps.paymentMethods[0].name);
		}
	}

	captchaBgClickListener() {
		if(this.recaptchaWindow) {
			this.recaptchaWindow.style.opacity = '1';
			this.recaptchaWindow.style.visibility = 'visible';
		}
	}

	handleProcessAbandonmentEmail() {
		const {onSendAbandonEmailRequest, yearlyPackage, yearly2Package, queryParams, currency, savingPercent, couponSavingPercent, planConfig, couponByCode, upgradeMode, queryParams: {active_vpn, current_plan}} = this.props;
		const {formFields: {email}, fieldsError: {formFields}, country} = this.state;
		const _queryParamsArray = Object.keys(queryParams) || [];

		const eu_logos = ['DE', 'FR', 'NL', 'IT', 'CH'].indexOf(country) > -1;
		const percent = parseInt((savingPercent || planConfig.savingPercent || couponSavingPercent || '').replace('%', ''));
		const price = parseFloat(utils.calculatePricePerMonth(((planConfig.showYearly2 || (upgradeMode && active_vpn && (current_plan === PlanTypes.FREE || current_plan === PlanTypes.OVERQUOTA))) ? yearly2Package : yearlyPackage), PlanTypes, currency, couponByCode));

		let abandon_link = window.location.href;
		let _queryString = '';

		if(_queryParamsArray.length) {
			for(let i=0; i<_queryParamsArray.length; i++) {
				_queryString += _queryParamsArray[i] + '=' + queryParams[_queryParamsArray[i]] + (i === _queryParamsArray.length - 1 ? '' : '&');
			}
			abandon_link += '?' + _queryString;
		}

		if(email && !formFields.email) {
			onSendAbandonEmailRequest({email, eu_logos, price, percent, abandon_link});
		}
	}

	checkValidationErrors(name, selectedPaymentMethod, blur) {
		const {t} = this.props;
		const {formFields, paymentFields, fieldsError, fieldsTouched, emailCheckErrorText, authCheckErrorText} = this.state;
		const _fieldsGroup = selectedPaymentMethod ? paymentFields[selectedPaymentMethod] : formFields;
		const _fieldsTouched = selectedPaymentMethod ? fieldsTouched[selectedPaymentMethod] : fieldsTouched['formFields'];
		const _fieldsError = {...fieldsError};
		let errorMessage = '';

		if(_fieldsTouched[name]) {
			if(!_fieldsGroup[name]) {
				errorMessage = t('This field is required.');
			} else {
				errorMessage = '';
				if(name !== 'email' && name !== 'password' && FIELD_CHECK_FOR_EMAIL_REGEXP.test(_fieldsGroup[name])) {
					errorMessage = t('Please Enter correct value');
				} else if(name === 'email') {
					if(!EMAIL_REGEXP.test(_fieldsGroup[name])) {
						errorMessage = t('Please Enter a Valid Email');
					} else {
						errorMessage = emailCheckErrorText || '';
					}
				} else if(name === 'password' && authCheckErrorText) {
					errorMessage = authCheckErrorText;
				} else if(name === 'expirationMonthYear') {
					const _month = (_fieldsGroup[name] || '').split('/')[0] || null;
					const _year = (_fieldsGroup[name] || '').split('/')[1] || null;
					const _currentYear = moment().format('YY');
					const _currentMonth = moment().format('MM');

					if(blur && _fieldsGroup[name].length < 5 && !DATE_REGEXP.test(_fieldsGroup[name])) {
						errorMessage = t('Date Format is MM/YY');
					}

					if(_fieldsGroup[name].length > 4 && (((+_month < +_currentMonth) && (+_year === +_currentYear)) || (+_year < +_currentYear))) {
						errorMessage = t('Please Enter Valid Date');
					}
				} else if(name === 'termsAndConditions') {
					errorMessage = _fieldsGroup[name] ? '' : t('Please Accept Our Terms');
				} else if(selectedPaymentMethod === REACT_APP_SEPA_DIRECT_DEBIT && (name === 'firstName' || name === 'lastName') && !LETTERS_REGEXP.test(_fieldsGroup[name])) {
					errorMessage = t('Please Enter Only Letters');
				}
			}

			if(errorMessage){
				if(selectedPaymentMethod) {
					_fieldsError[selectedPaymentMethod][name] = errorMessage;
				} else {
					_fieldsError['formFields'][name] = errorMessage;
				}
			} else {
				if(selectedPaymentMethod) {
					delete _fieldsError[selectedPaymentMethod][name];
				} else {
					delete _fieldsError['formFields'][name];
				}
			}
			this.setState({fieldsError: _fieldsError});
		}
	}

	handleChangeCountry(country) {
		this.setState({country},() => {
			this.props.onGetCurrency(country, cookies.get('currencyCode')).then(available_currencies => {
				const _currency = this.props.queryParams?.currency_code || available_currencies[0] || 'USD';
				const _token = this.props.queryParams?.token;
				const show_sdd_method = this.props.queryParams.show_sdd_method;
				this.props.onChangeCountry(country);
				this.props.onChangeCurrency(_currency);
				this.props.onGetPaymentMethods({token: _token, show_sdd_method, currency: _currency, selected_country: country, show_all_methods_with_no_limitations: (location.pathname.indexOf('bfa9b7aa5dfd833775f3cf093846b04d') > -1)});
			});
		});
	}

	handleChangeAccountInfo(value, name, selectedPackage = {}, nqp = {}) {
		const {fieldsTouched, country} = this.state;
		const {t, currency, queryParams, securityDetails: {is_proxy_detected}, onUpdateQueryParams} = this.props;
		const _qp = {...(Object.keys(nqp).length ? nqp : queryParams)};
		if(is_proxy_detected) {
			return;
		}

		if(name === 'email') { value = value.replace(/\s/g, ''); }

		const _fieldsGroup = this.state['formFields'];
		const _formFields = {..._fieldsGroup, [name]: value};
		const _fieldsTouched = {...fieldsTouched};

		const _updateState = (name, newState) => {
			if(this._isMounted) {
				this.setState({...newState, authCheckLoading: false}, () => {
					this.checkValidationErrors(name);
				});
			}
		};

		if(name === 'email') {
			this.maskEmailField.textContent = value;
			if(this.emailField) {
				this.emailField.style.width = this.maskEmailField.offsetWidth > 0 ?  (this.maskEmailField.offsetWidth + 'px') : '100%';
			}
			if(EMAIL_REGEXP.test(value)) {
				this.setState({authCheckLoading: true, btnDisabled: true, emailSuccess: false, emailCheckErrorText: '', withPassword: null, authSuccess: false, authCheckErrorText: '', order_id: '', returning_customer: undefined}, () => {
					clearTimeout(window.checkEmailTicker);
					window.checkEmailTicker = setTimeout(() => {
						this.props.onCheckEmail({email: value}).then((response) => {
							const _checkResults = response.data || {};
							if(_checkResults.currency_code) {
								this.props.onChangeCurrency(_checkResults.currency_code);
								this.props.onGetPaymentMethods({token: _qp.token, show_sdd_method: _qp.show_sdd_method, currency: _checkResults.currency_code, selected_country: country, show_all_methods_with_no_limitations: (location.pathname.indexOf('bfa9b7aa5dfd833775f3cf093846b04d') > -1)});
							} else {
								this.props.onChangeCurrency(currency);
							}
							let _stateToUpdate = {btnDisabled: false, emailSuccess: true};
							if(response.code === 206) {
								_stateToUpdate.withPassword = false;
								_updateState(name, _stateToUpdate);
							} else if(_checkResults.currency_code) {
								if(_qp.access_token) {
									_stateToUpdate.returning_customer = _qp.returning_customer;
									_stateToUpdate.authSuccess = true;
									_stateToUpdate.btnDisabled = false;
									sessionStorage?.setItem('PV_access_token', _qp.access_token);
									sessionStorage?.setItem('email', value);
									if(_qp.order_id) {
										_stateToUpdate.order_id = _qp.order_id;
									}
									_updateState(name, _stateToUpdate);
								} else if(_qp.id && (!_qp.email || _qp.email === value)) {
									if(!_qp.email) {
										_qp.email = value;
									}
									_stateToUpdate.withPassword = false;
									_stateToUpdate.btnDisabled = false;
									_updateState(name, _stateToUpdate);
								} else {
									const _dataForUserAuth = {
										email: value,
										password: _fieldsGroup.password,
										package_id: selectedPackage.packageIdentifier,
										amount: (selectedPackage.recurringPeriod.basePrice || []).filter(b => b.currencyCode === currency)[0].amount,
										currency
									};
									_stateToUpdate.withPassword = true;
									_stateToUpdate.btnDisabled = true;
									if(_fieldsGroup.password.length) {
										this.props.onUserAuth(_dataForUserAuth)
											.then(response => {
												const _response = response.data || {};
												if(response.error || response.status === 'Bad Request') {
													_stateToUpdate.authSuccess = false;
													_stateToUpdate.btnDisabled = true;
													_stateToUpdate.authCheckErrorText = t('The email or password provided is incorrect');
												} else {
													_stateToUpdate.returning_customer = _response.returning_customer;
													_stateToUpdate.order_id = _response.order_id;
													if(_response.access_token) {
														_stateToUpdate.authSuccess = true;
														_stateToUpdate.btnDisabled = false;
														sessionStorage?.setItem('PV_access_token', _response.access_token);
														onUpdateQueryParams({..._qp, ..._response});
													}
												}
												_updateState(name, _stateToUpdate);
											});
									} else {
										_updateState(name, _stateToUpdate);
									}
								}
							} else if(response.error) {
								const _errorText = _checkResults.error || t('The email provided is incorrect');
								_stateToUpdate.btnDisabled = true;
								_stateToUpdate.emailSuccess = false;
								_stateToUpdate.emailCheckErrorText = _errorText.indexOf('We detected you are using a proxy') > -1 ? (<span>We detected you are using a proxy, please disconnect your proxy before placing an order or <a href="https://support.privadovpn.com/home/" target="_blank" rel="noreferrer">contact our support</a></span>) : _errorText;
								_updateState(name, _stateToUpdate);
							} else {
								_updateState(name, _stateToUpdate);
							}
						});
					}, 400);
				});
			}
		} else if(name === 'password' && value) {
			let _stateToUpdate = {btnDisabled: true, authSuccess: false, authCheckErrorText: '', order_id: '', returning_customer: undefined};
			_updateState(name, _stateToUpdate);
		}
		_fieldsTouched['formFields'][name] = true;

		this.setState({formFields: _formFields, fieldsTouched: _fieldsTouched}, () => {
			this.checkValidationErrors(name);
		});
	}

	handleLogin() {
		const { 
			t, 
			selectedPackage, 
			currency, 
			securityDetails: {is_proxy_detected, iso_code}, 
			queryParams,
			handleScrollToPaymentSection, 
			onUserAuth, 
			onUpdateQueryParams 
		} = this.props;
		const { authSuccess, formFields } = this.state;

		if(is_proxy_detected) {
			return;
		}

		if(authSuccess) {
			const _stateInitial = {
				formFields: {
					email: '',
					password: '',
					selectedPaymentMethod: ''
				},
				paymentFields: {},

				fieldsError: {formFields: {}},
				fieldsTouched: {formFields: {}},

				country: iso_code || '',
				btnDisabled: true,
				emailCheckErrorText: '',
				emailSuccess: false,
				withPassword: null,
				authCheckErrorText: '',
				authSuccess: false,
				order_id: '',
				returning_customer: undefined
			};
			this.setState(_stateInitial);
		} else {
			let _stateToUpdate = {authSuccess: false, authCheckErrorText: '', order_id: '', returning_customer: undefined, isLoggingIn: false};
			const _dataForUserAuth = {
				email: formFields.email,
				password: formFields.password,
				package_id: selectedPackage.packageIdentifier,
				amount: (selectedPackage.recurringPeriod.basePrice || []).filter(b => b.currencyCode === currency)[0].amount,
				currency
			};
			this.setState({isLoggingIn: true}, () => {
				onUserAuth(_dataForUserAuth)
					.then(response => {
						if(response.error || response.status === 'Bad Request') {
							_stateToUpdate.authCheckErrorText = t('Invalid Password.');
						} else {
							_stateToUpdate.returning_customer = response?.data?.returning_customer;
							_stateToUpdate.order_id = response?.data?.order_id;
							if(response?.data?.token && response?.data?.access_token) {
								_stateToUpdate.authSuccess = true;
								_stateToUpdate.btnDisabled = false;
								sessionStorage?.setItem('PV_token', response?.data?.token);
								sessionStorage?.setItem('PV_access_token', response?.data?.access_token);
								onUpdateQueryParams({...queryParams, ...response?.data});
								handleScrollToPaymentSection();
							}
						}
						this.setState(_stateToUpdate, () => {
							this.checkValidationErrors('password');
						});
					});
			});
		}
	}

	handleChangePaymentMethod(value) {
		this.setState((state) => {
			const {fieldsTouched, formFields} = state;
			const _fieldsTouched = {...fieldsTouched};

			_fieldsTouched['formFields']['selectedPaymentMethod'] = true;

			return {
				formFields: {
					...formFields,
					selectedPaymentMethod: value
				},
				fieldsTouched: _fieldsTouched
			};
		}, () => {
			this.checkValidationErrors('selectedPaymentMethod');
		});
	}

	handleChangePaymentFields(value, name, e) {
		const {formFields: {selectedPaymentMethod}, paymentFields, fieldsTouched} = this.state;
		const _paymentFields = {...paymentFields};
		const _fieldsTouched = {...fieldsTouched};
		const _value  = (selectedPaymentMethod === REACT_APP_SEPA_DIRECT_DEBIT && (name === 'firstName' || name === 'lastName'))  ? tr(value) : value;

		if(!_paymentFields[selectedPaymentMethod]) {
			_paymentFields[selectedPaymentMethod] = {};
		}
		if(!_fieldsTouched[selectedPaymentMethod]) {
			_fieldsTouched[selectedPaymentMethod] = {};
		}

		_paymentFields[selectedPaymentMethod][name] = _value;
		_fieldsTouched[selectedPaymentMethod][name] = true;

		if(name === 'expirationMonthYear') {
			const _expField = _paymentFields[selectedPaymentMethod];
			_expField.expirationMonthYear  = _expField.expirationMonthYear.replace(/\D/g,'');

			if(_expField.expirationMonthYear[0] && +(_expField.expirationMonthYear[0]) > 1) {
				_expField.expirationMonthYear = '0' + _expField.expirationMonthYear;
			}

			if(+(_expField.expirationMonthYear[0]) === 1 && _expField.expirationMonthYear[1] && +(_expField.expirationMonthYear[1]) > 2) {
				_expField.expirationMonthYear = '0' + _expField.expirationMonthYear[0] + '/' + _expField.expirationMonthYear[1];
			}

			if((_expField.expirationMonthYear || '').length > 4) {
				_expField.expirationMonthYear = _expField.expirationMonthYear.substring(0,4);
			}

			if((_expField.expirationMonthYear || '').length >1 && (_expField.expirationMonthYear || '').length < 5) {
				_expField.expirationMonthYear = _expField.expirationMonthYear.substring(0, 2) + '/' + _expField.expirationMonthYear.substring(2, (_expField.expirationMonthYear || '').length);
			}

			if((/^\d\d\/\d\/$/).test(_expField.expirationMonthYear)) {
				_expField.expirationMonthYear = _expField.expirationMonthYear.substring(0,4);
			}

			if(e && (e.keyCode === 8) && (_expField.expirationMonthYear || '').length === 3) { //handle backspace for `/`
				_expField.expirationMonthYear = _expField.expirationMonthYear.substring(0,2);
			} else {
				_expField.expirationMonthYear = _expField.expirationMonthYear.replace(
					/^([1-9]\/|[2-9])$/g, '0$1/' // 3 > 03/
				).replace(
					/^(0[1-9]|1[0-2])$/g, '$1/' // 11 > 11/
				).replace(
					/^(1)([3-9])$/g, '0$1/$2' // 13 > 01/3
				).replace(
					/^([0]+)\/|[0]+$/g, '0' // 0/ > 0 and 00 > 0
				).replace(
					/[^\d/]|^[/]*$/g, '' // To allow only digits and `/`
				).replace(
					/\/\//g, '/' // Prevent entering more than 1 `/`
				);
			}
		}
		this.setState({paymentFields: _paymentFields, fieldsTouched: _fieldsTouched}, () => {
			this.checkValidationErrors(name, selectedPaymentMethod);
		});
	}

	handleOpenCouponCodeField() {
		this.setState({isCouponCodeFieldOpened: true});
	}

	handleChangeCouponCodeField(couponCode) {
		const {couponByCode, onResetCouponByCode} = this.props;
		this.setState({couponCode: couponCode.replace(/\s/g, '')}, () => {
			if(!couponCode && Object.keys(couponByCode).length) {
				onResetCouponByCode();
			}
		});
	}

	handleSubmitCouponCode() {
		const { couponCode, authSuccess, formFields: { email } } = this.state;
		const { selectedPackage, onGetCouponByCode, handleSelectPackage, yearlyPackage, sentryProps } = this.props;
		const _email = authSuccess ? email : null;
		onGetCouponByCode(couponCode, selectedPackage.packageIdentifier, (sentryProps[selectedPackage.packageIdentifier] ? ['SENTRYS'] : []), _email)
			.then(response => {
				const couponValidPlan = response.validPlans && response.validPlans[0] || '';
				if(response.success && couponValidPlan === PlanTypes.YEARLY && selectedPackage.packageIdentifier !== PlanTypes.YEARLY) {
					handleSelectPackage({...yearlyPackage, initialSelectedPackageId: selectedPackage.packageIdentifier});
				}
			});
	}

	handleBlur(name, selectedPaymentMethod) {
		const {fieldsTouched} = this.state;
		const _fieldsTouched = selectedPaymentMethod ? fieldsTouched[selectedPaymentMethod] : fieldsTouched['formFields'];
		_fieldsTouched[name] = true;

		this.setState({fieldsTouched: {...fieldsTouched}}, () => {
			this.checkValidationErrors(name, selectedPaymentMethod, true);
		});
	}

	handlePaymentFieldsInit(fieldsToUpdate) {
		const {formFields: {selectedPaymentMethod}, paymentFields, fieldsTouched, fieldsError} = this.state;
		this.setState({
			paymentFields: {...paymentFields, ...fieldsToUpdate},
			fieldsTouched: {...fieldsTouched, [selectedPaymentMethod]: {}},
			fieldsError: {...fieldsError, [selectedPaymentMethod]: {}}
		});
	}

	handleCreateFreemium(recaptchaToken) {
		const {formFields} = this.state;
		const {navigate, currency, securityDetails: {is_proxy_detected}, i18n, handleSelectPackage, yearlyPackage, queryParams: {token, username}, onCreateFreemium, onReactivateFreemium} = this.props;
		const _meta = cookies.get('privadometa') || {};

		if(is_proxy_detected) {
			return;
		}

		const dataToSend = {
			email: formFields.email,
			currency,
			web: true,
			platform: 'web',
			user_metadata: {..._meta},
			return_url: location.pathname.replace('/', '') || ''
		};

		if(cookies.get('streaming')) {
			dataToSend.streaming = true;
			cookies.remove('streaming', {path: '/', domain: (window.location.hostname  || '').replace('signup.', '')});
		}

		if(dataToSend.user_metadata.d1) {
			dataToSend.user_metadata.campaign_d1 = dataToSend.user_metadata.d1;
			delete dataToSend.user_metadata.d1;
		}
		if(dataToSend.user_metadata.d2) {
			dataToSend.user_metadata.campaign_d2 = dataToSend.user_metadata.d2;
			delete dataToSend.user_metadata.d2;
		}
		if(!dataToSend.user_metadata.TransactionURL) {
			dataToSend.user_metadata.TransactionURL = window.location.pathname;
			cookies.set('privadometa', JSON.stringify({..._meta, TransactionURL: window.location.pathname}), { path: '/', domain: (window.location.hostname  || '').replace('signup.', '') });
		}
		if(recaptchaToken) {
			dataToSend['g-recaptcha-response'] = recaptchaToken;
		}

		if(token) {
			return onReactivateFreemium(username)
				.then(response => {
					if(response.code === 200) {
						window.location.href = REACT_APP_URL + '/' + i18n.resolvedLanguage + '/admin-panel?inapp=1';
					}
				});
		} else {
			return onCreateFreemium(dataToSend)
				.then(response => {
					if(response.success) {
						navigate((i18n.resolvedLanguage === 'en' ? '' : '/' + i18n.resolvedLanguage) + '/verification');
						handleSelectPackage(yearlyPackage);
					}
					return response;
				});
		}
	}

	handleSubmit(recaptchaToken) {
		const {formFields, paymentFields, withPassword, order_id, country} = this.state;
		const {
			selectedPackage,
			queryParams,
			currency,
			paymentMethods,
			onCreateSavedPayment,
			onCreatePaypalPayment,
			onProcessTokenize,
			onCreatePayment,
			onCreateIdealPayment,
			onCreateSepaPayment,
			onCreateCoinbasePayment,
			onCreateCoinbillPayment,
			onCreatePaymentWall,
			onGetIP,
			couponByCode,
			couponMonthly,
			couponYearly,
			couponYearly2,
			securityDetails: {is_proxy_detected},
			sentryProps,
			planConfig
		} = this.props;
		const _meta = cookies.get('privadometa') || {};
		const selectedPaymentMethod = formFields.selectedPaymentMethod || null;
		const __pf = paymentFields[selectedPaymentMethod];
		const _paymentFields = {...__pf};
		const _savedPaymentMethod = paymentMethods.find(p => p.name === REACT_APP_SAVED_PAYMENT);

		if(is_proxy_detected) {
			return;
		}

		const dataToSend = {
			email: formFields.email,
			currency,
			package_id: selectedPackage.packageIdentifier,
			price: (selectedPackage.recurringPeriod.basePrice || []).filter(b => b.currencyCode === currency)[0].amount,
			amount: (selectedPackage.recurringPeriod.basePrice || []).filter(b => b.currencyCode === currency)[0].amount,
			billing_method:  selectedPaymentMethod,
			platform: 'web',
			return_url: location.pathname.replace('/', '') || ''
		};

		if(recaptchaToken) {
			dataToSend['g-recaptcha-response'] = recaptchaToken;
		}

		if(cookies.get('streaming')) {
			dataToSend.streaming = true;
			cookies.remove('streaming', {path: '/', domain: (window.location.hostname  || '').replace('signup.', '')});
		}

		if(couponYearly2.code) {
			const couponYearly2ValidPlan = couponYearly2.validPlans && couponYearly2.validPlans[0] || '';
			if(selectedPackage.packageIdentifier === couponYearly2ValidPlan) {
				dataToSend.coupon = couponYearly2.code;
			}
		}

		if(couponYearly.code) {
			const couponYearlyValidPlan = couponYearly.validPlans && couponYearly.validPlans[0] || '';
			if(selectedPackage.packageIdentifier === couponYearlyValidPlan) {
				dataToSend.coupon = couponYearly.code;
			}
		}

		if(couponMonthly.code) {
			const couponMonthlyValidPlan = couponMonthly.validPlans && couponMonthly.validPlans[0] || '';
			if(selectedPackage.packageIdentifier === couponMonthlyValidPlan) {
				dataToSend.coupon = couponMonthly.code;
			}
		}

		if(couponByCode.code) {
			const couponValidPlan = couponByCode.validPlans && couponByCode.validPlans[0] || '';
			const couponPriceObj = couponByCode.typeParameters && couponByCode.typeParameters.fixedPrice || {};
			if(selectedPackage.packageIdentifier === couponValidPlan) {
				dataToSend.coupon = couponByCode.code;
				dataToSend.amount = +(couponPriceObj[dataToSend.currency] || dataToSend.amount);
			}
		}

		dataToSend.user_metadata = {..._meta};
		if(dataToSend.user_metadata.d1) {
			dataToSend.user_metadata.campaign_d1 = dataToSend.user_metadata.d1;
			delete dataToSend.user_metadata.d1;
		}
		if(dataToSend.user_metadata.d2) {
			dataToSend.user_metadata.campaign_d2 = dataToSend.user_metadata.d2;
			delete dataToSend.user_metadata.d2;
		}
		if(!dataToSend.user_metadata.TransactionURL) {
			dataToSend.user_metadata.TransactionURL = window.location.pathname;
			cookies.set('privadometa', JSON.stringify({..._meta, TransactionURL: window.location.pathname}), { path: '/', domain: (window.location.hostname  || '').replace('signup.', '') });
		}

		sessionStorage?.setItem('paymentSubmitted', true);
		sessionStorage?.setItem('returnUrl', dataToSend.return_url);
		sessionStorage?.setItem('email', dataToSend.email);

		const extraData = {
			BrowserAcceptHeader: 'application/json',
			BrowserJavaEnabled: navigator.javaEnabled(),
			BrowserLanguage: navigator.language,
			BrowserColorDepth: screen.colorDepth,
			BrowserScreenHeight: screen.height,
			BrowserScreenWidth: screen.width,
			BrowserTimezone:  (new Date()).getTimezoneOffset(),
			BrowserUserAgent: navigator.userAgent
		};

		const {address, city, state, zipCode, firstName, lastName, bic, iban, postcode} = _paymentFields;

		if(order_id) {
			dataToSend.order_id = order_id;
		}
		if(withPassword === false) {
			dataToSend.password = '';
			if(queryParams && queryParams.id) {
				dataToSend.order_id = queryParams.id;
			}
		}

		if(planConfig.sentry && sentryProps[dataToSend.package_id]) {
			const _baseSentryPrice = ((selectedPackage?.addonPackages || []).find(a => a.packageIdentifier.indexOf('SENTRY') > -1)?.recurringPeriod?.basePrice || []).find(b => b.currencyCode === currency);
			const _amountSentry = _baseSentryPrice?.amount || 0;

			dataToSend.addons = ['SENTRYS'];
			if(couponByCode.validPlans && dataToSend.package_id === couponByCode.validPlans[0]) {
				if(couponByCode.grace_period_months === 27) {
					dataToSend.coupon_sentry = 'sentry2403';
					if(!couponByCode.includeSentry) {
						dataToSend.amount += 27*_amountSentry;
					}
				}
				if(couponByCode.grace_period_months === 15) {
					dataToSend.coupon_sentry = 'sentry1203';
					if(!couponByCode.includeSentry) {
						dataToSend.amount += 15*_amountSentry;
					}
				}
			} else if(couponYearly.validPlans && dataToSend.package_id === couponYearly.validPlans[0]) {
				dataToSend.coupon_sentry = 'sentry1203';
				dataToSend.coupon = couponYearly.code + (couponYearly.code.indexOf('brazil') > -1 ? '-s' : '-S');
				if(!couponByCode.includeSentry) {
					dataToSend.amount += 15*_amountSentry;
				}
			} else if(couponYearly2.validPlans && dataToSend.package_id === couponYearly2.validPlans[0]) {
				dataToSend.coupon_sentry = 'sentry2403';
				dataToSend.coupon = couponYearly2.code + (couponYearly.code.indexOf('brazil') > -1 ? '-s' : '-S');
				if(!couponByCode.includeSentry) {
					dataToSend.amount += 27*_amountSentry;
				}
			}
		}

		dataToSend.price = dataToSend.amount = (Math.round(dataToSend.amount*100)/100).toFixed(2);

		if(selectedPaymentMethod === REACT_APP_SAVED_PAYMENT) {
			dataToSend.billing_method = _savedPaymentMethod?.billingMethod;
			dataToSend.flexible_id = _savedPaymentMethod.flexibleId;

			return onCreateSavedPayment(dataToSend)
				.then(response => {
					if(response?.data?.redirect_url) {
						sessionStorage?.removeItem('returnUrl');
						setTimeout(() => {
							window.location.href = response.data.redirect_url;
						}, 1000);
					}
					return response;
				});
		} else if(selectedPaymentMethod === REACT_APP_PAYPAL_FLEXIBLE) {
			return onCreatePaypalPayment(dataToSend)
				.then(response => {
					if(response?.data?.redirect_url) {
						sessionStorage?.removeItem('returnUrl');
						setTimeout(() => {
							window.location.href = response.data.redirect_url;
						}, 1000);
					}
					return response;
				});
		} else {
			const processingResponse = response => {
				if(response?.data?.redirect_url) {
					sessionStorage?.removeItem('returnUrl');
					window.location.href = response.data.redirect_url;
				}
				return response;
			};

			return onGetIP(selectedPaymentMethod).then(data => {
				if(data.ip) {
					extraData.BrowserIpAddress = data.ip;
				}
				dataToSend.extraData = JSON.stringify(extraData);

				if(selectedPaymentMethod === REACT_APP_CREDIT_CARD) {
					onProcessTokenize();
					if(_paymentFields.expirationMonthYear) {
						const _expirationMonthYearArray = _paymentFields.expirationMonthYear.split('/');
						_paymentFields.ccDate = _expirationMonthYearArray[0];
						_paymentFields.ccYear = '20' + _expirationMonthYearArray[1];
					}

					customService.getTransactionToken(this.props.paymentJS, selectedPaymentMethod, {...formFields, ..._paymentFields}, null).subscribe(transactionResponse => {
						if(!transactionResponse.errors) {
							dataToSend.token = transactionResponse.transactionToken;
							dataToSend.first_six_chars_of_card = transactionResponse.first_six_chars_of_card;

							return onCreatePayment({...dataToSend, firstName, lastName, address, city, state, postcode: zipCode})
								.then(response => {
									processingResponse(response);
								});
						}
					});
				} else if(selectedPaymentMethod === REACT_APP_IDEAL) {
					return onCreateIdealPayment({...dataToSend, firstName, lastName, bic})
						.then(response => {
							processingResponse(response);
						});
				} else if(selectedPaymentMethod === REACT_APP_SEPA_DIRECT_DEBIT) {
					return onCreateSepaPayment({
						...dataToSend, 
						firstName, 
						lastName, 
						iban, 
						address, 
						city, 
						postcode,
						country
					}).then(response => {
						processingResponse(response);
					});
				} else if(selectedPaymentMethod === REACT_APP_COINBASE) {
					return onCreateCoinbasePayment(dataToSend)
						.then(response => {
							processingResponse(response);
						});
				} else if(selectedPaymentMethod === REACT_APP_COINBILL) {
					return onCreateCoinbillPayment(dataToSend)
						.then(response => {
							processingResponse(response);
						});
				} else if(selectedPaymentMethod.indexOf(REACT_APP_PAYMENTWALL) > -1) {
					dataToSend.billing_method = _paymentFields.selectedPaymentWallMethod;

					return onCreatePaymentWall(dataToSend)
						.then(response => {
							processingResponse(response);
						});
				}
			});
		}

	}

	handleSubmitForm(e, isFreemium) {
		const {securityDetails: {slow_mode}, onCaptchaIsProcessing} = this.props;
		const {formFields: {selectedPaymentMethod}} = this.state;
		const _submitForm = isFreemium ? this.handleCreateFreemium : this.handleSubmit;
		const _paymentType = isFreemium ? 'FREEMIUM' : selectedPaymentMethod;

		this.recaptchaWindow = [...document.getElementsByTagName('iframe')]?.find((x) => x.src.includes((REACT_APP_RECAPTCHA_SRC === 'useRecaptchaNet' ? 'recaptcha.net' : 'google.com') + '/recaptcha/api2/bframe'))?.parentNode?.parentNode;
		if(this.recaptchaWindow) {
			this.recaptchaWindow?.firstChild?.addEventListener('click', this.captchaBgClickListener);
		}

		if(slow_mode && this.recaptchaIsLoaded && this.recaptchaRef?.current) {
			onCaptchaIsProcessing(_paymentType, true);
			this.recaptchaRef.current.executeAsync()
				.then(token => {
					if(token) {
						_submitForm(token).then(() => {
							this.recaptchaRef.current.reset();
						});
					} else {
						onCaptchaIsProcessing(_paymentType, false, null, 'captchaError');
						this.recaptchaRef.current.reset();
					}
				})
				.catch(err => {
					onCaptchaIsProcessing(_paymentType, false, err, 'captchaError');
					this.recaptchaRef.current.reset();
					console.log('recaptcha', err);
				});
		} else if(isFreemium && this.recaptchaFreeIsLoaded && this.recaptchaFreeRef?.current) {
			onCaptchaIsProcessing(_paymentType, true);
			this.recaptchaFreeRef.current.executeAsync()
				.then(token => {
					if(token) {
						this.handleCreateFreemium(token).then(() => {
							this.recaptchaFreeRef.current.reset();
						});
					} else {
						onCaptchaIsProcessing(_paymentType, false, null, 'freeCaptchaError');
						this.recaptchaFreeRef.current.reset();
					}
					
				})
				.catch(err => {
					onCaptchaIsProcessing(_paymentType, false, err, 'freeCaptchaError');
					this.recaptchaFreeRef.current.reset();
					console.log('recaptchaFreeRef', err);
				});
		} else {
			_submitForm();
		}
	}

	handleCaptchaLoad(e, recaptcha, slow_mode, reCreateFreemium) {
		this[recaptcha] = e.loaded;
		if(e.errored) {
			utils.captureSentryMessage({
				message: `Failed To Load Recaptcha Script - ${(REACT_APP_RECAPTCHA_SRC === 'useRecaptchaNet' ? 'recaptcha.net' : 'google.com')} - ${this.state.country}`,
				messageType: 'info',
				errorType: 'recaptcha_script_load_error',
				recaptchaType: (slow_mode ? 'slowMode' : 'free'),
				design: 'v2',
				country: this.state.country,
				url: (REACT_APP_RECAPTCHA_SRC === 'useRecaptchaNet' ? 'recaptcha.net' : 'google.com')
			});
		} else if(reCreateFreemium) {
			this.handleSubmitForm(e, true);
		}
	}

	render() {
		const {
			formFields,
			paymentFields,
			fieldsError,
			fieldsTouched,
			btnDisabled,
			emailSuccess,
			emailCheckErrorText,
			withPassword,
			authSuccess,
			authCheckErrorText,
			returning_customer,
			couponCode,
			isCouponCodeFieldOpened,
			order_id,
			authCheckLoading,
			isLoggingIn,
			country
		} = this.state;
		const {
			i18n,
			t,
			packages,
			planConfig,
			couponMonthly,
			couponYearly2,
			couponYearly,
			couponPrice,
			couponGracePeriod,
			couponValidPlan,
			couponSavingPercent,
			couponRenewalPrice,
			couponByCode,
			couponByCodeIsLoading,
			currency,
			classes,
			paymentJS,
			paymentMethods,
			selectedPackage,
			saving,
			savingPercent,
			saving2Percent,
			createSavedPaymentLoading,
			createPaymentLoading,
			createPaypalPaymentIsLoading,
			createIdealPaymentLoading,
			createSepaPaymentLoading,
			createCoinbasePaymentLoading,
			createCoinbillPaymentLoading,
			createPaymentWallLoading,
			freemiumProcessing,
			securityDetails: {is_proxy_detected, slow_mode},
			queryParams: {id, token, active_vpn},
			upgradeMode,
			iDealBankList,
			allowedCountries,
			freeRecaptchaChecked,
			cryptoLogos
		} = this.props;

		return (
			<Box className={classes.paymentSection}>
				{slow_mode ?
					<ReCAPTCHA
						ref={this.recaptchaRef}
						size="invisible"
						sitekey={slow_mode}
						asyncScriptOnLoad={(e) => this.handleCaptchaLoad(e, 'recaptchaIsLoaded', slow_mode)}
					/> : 
					<FreeRecaptcha 
						selectedPackage={selectedPackage} 
						recaptchaFreeRef={this.recaptchaFreeRef}
						email={formFields.email}
						emailSuccess={emailSuccess}
						asyncScriptOnLoad={(e, reCreateFreemium) => this.handleCaptchaLoad(e, 'recaptchaFreeIsLoaded', null, reCreateFreemium)}
					/>
				}

				{selectedPackage.packageIdentifier ?
					<Container maxWidth="md" component="main" className={classes.formWrapper} >
						<Grid container spacing={5} alignItems="center" justify="center">
							<Grid item xs={12}>
								{upgradeMode ?
									<Typography component="div" className={classes.separator}></Typography> :
									<Typography component="h1" variant="h4" align="left" color="textPrimary" gutterBottom className={classes.sectionHeader}>
										{(packages.length > 1 ? '2.' : '') + t('Create Account:')}
									</Typography>
								}
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container className={clsx(classes.accountInfoBlock)}>
									<Grid item xs={12} className={classes.fieldContainer}>
										<TextField
											variant="outlined"
											fullWidth
											id="email"
											label={t('Email address')}
											placeholder={t('Enter email address')}
											InputLabelProps={{
												shrink: true,
											}}
											name="email"
											classes={{root: classes.formInput}}
											value={formFields.email}
											inputProps={{
												style: { display: 'inline-block', maxWidth: '100%' },
											}}
											InputProps={{
												endAdornment: <InputAdornment position="end">{authCheckLoading ? <LoaderSmall xs={true} color={'grey'} /> : selectedPackage.packageIdentifier !== 'FREE' && emailSuccess && (!fieldsError['formFields'].email && !emailCheckErrorText) ? <span className={classes.emailSuccessIcon}></span> : ''}</InputAdornment>,
											}}
											error={!!fieldsError['formFields'].email || !!emailCheckErrorText  || (selectedPackage.packageIdentifier === 'FREE' && (!token && (withPassword || !!id || !!order_id) || active_vpn))}
											helperText={(selectedPackage.packageIdentifier === 'FREE' && (!token && (withPassword || id || order_id) || active_vpn)) ?  <span className={classes.errorIcon}><Trans i18nKey="transEmailExists">This email address already exists. Please <a href={REACT_APP_URL + '/' + i18n.resolvedLanguage + '/sign-in'} target="_blank" rel="noopener noreferrer">log in</a> to upgrade your account.</Trans></span> : (fieldsError['formFields'].email || emailCheckErrorText) ? <span className={classes.errorIcon}>{fieldsError['formFields'].email || emailCheckErrorText}</span> : ''}
											onChange={e => this.handleChangeAccountInfo(e.target.value, 'email', selectedPackage)}
											onBlur={() => this.handleBlur('email')}
											autoComplete="email"
											disabled={!!token || upgradeMode || is_proxy_detected}
										/>
										{(couponByCode.code === 'winback' && ((typeof returning_customer === 'boolean') && returning_customer === false || emailSuccess && !withPassword)) ?
											<div className={classes.formErrorText}>{t('We\'re sorry. This plan is not available on your account at this time.')}</div> :
											null
										}
									</Grid>

									{selectedPackage.packageIdentifier === 'FREE' ?
										<Fragment>
											<Grid item xs={12} className={classes.termsContainer}>
												<span className={classes.terms}>
													<Trans i18nKey="transTermsAndConditions">
														I have read the <a color="textPrimary" href={REACT_APP_WEB + utils.combineRoute((i18n.resolvedLanguage === 'en' ? '' : i18n.resolvedLanguage), '/terms-of-service/')} target="_blank" rel="noopener noreferrer" >Terms and Conditions / Refund Policy</a> and agree to it.
													</Trans>
												</span>
											</Grid>
											<Grid item xs={12} >
												<div className={classes.freemiumWrapper}>
													<Button
														type="submit"
														fullWidth
														variant="contained"
														color="primary"
														className={clsx(classes.button, classes.freemiumSubmit)}
														disabled={!slow_mode && !freeRecaptchaChecked || is_proxy_detected || freemiumProcessing || !emailSuccess || !EMAIL_REGEXP.test(formFields.email) || !!fieldsError['formFields'].email || !!emailCheckErrorText || !token && (withPassword || !!id || !!order_id) || active_vpn} 
														onClick={e => this.handleSubmitForm(e, true)}
													>
														{freemiumProcessing ? <LoaderSmall /> : null}
														{t(freemiumProcessing ? 'Processing' : 'Create Your Free Account')}
													</Button>
													{is_proxy_detected ?
														<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
													}
												</div>
											</Grid>
										</Fragment> :
										<Fragment>
											{withPassword && !upgradeMode ?
												<Fragment>
													{authSuccess ? null :
														<Grid item xs={12} className={classes.fieldContainer} >
															<TextField
																variant="outlined"
																fullWidth
																id="password"
																type="password"
																label={t('Password')}
																placeholder={t('Enter password')}
																InputLabelProps={{
																	shrink: true,
																}}
																name="password"
																classes={{root: classes.formInput}}
																value={formFields.password}
																error={!!fieldsError['formFields'].password || !!authCheckErrorText}
																helperText={(fieldsError['formFields'].password || authCheckErrorText) ? <span className={classes.errorIcon}>{fieldsError['formFields'].password || authCheckErrorText}</span> : ''}
																onChange={e => this.handleChangeAccountInfo(e.target.value, 'password', selectedPackage)}
																onBlur={() => this.handleBlur('password')}
															/>
														</Grid >
													}
													<div className={classes.loginBtnWrapper}>
														{authSuccess ? null :
															<div className={classes.loginBtnContainer}>
																<a color="textPrimary" href={REACT_APP_URL + '/' + i18n.resolvedLanguage + '/forgot-password'} target="_blank" rel="noopener noreferrer" >{t('Forgot Password?')}</a>
																<Button
																	type="submit"
																	fullWidth
																	variant="contained"
																	color="primary"
																	className={clsx(classes.button, classes.loginButton)}
																	disabled={is_proxy_detected || isLoggingIn || !EMAIL_REGEXP.test(formFields.email) || !!fieldsError['formFields'].email || !!emailCheckErrorText || (withPassword && (!formFields.password)) || (couponByCode.code === 'winback' && ((typeof returning_customer === 'boolean') && returning_customer === false || emailSuccess && !withPassword))}
																	onClick={this.handleLogin}
																>
																	{t('Login')}
																</Button>
															</div>
														}
														{is_proxy_detected ?
															<div className={classes.errorIcon}>{t('We detected you are using a proxy, please disconnect your proxy before placing an order.')}</div>: null
														}
													</div>
												</Fragment> : null
											}
										</Fragment>
									}

								</Grid>
							</Grid>
						</Grid>
					</Container>:
					null
				}

				{paymentMethods.length && selectedPackage.packageIdentifier ?
					<Fragment>
						<Container maxWidth="md" component="main" style={(selectedPackage.packageIdentifier === 'FREE') ? {display: 'none'} : {}}>
							<Grid container spacing={5} alignItems="center" justify="center">
								<Grid item xs={12}>
									<Typography component="h1" variant="h4" align="left" color="textPrimary" gutterBottom className={classes.sectionHeader}>
										{(packages.length > 1 ? ((upgradeMode ? '2.' : '3.')) : '') + t('Select Payment:')}
									</Typography>
								</Grid>
							</Grid>
						</Container>
						<Container maxWidth="md" className="paymentSectionWrapper v2" style={(selectedPackage.packageIdentifier === 'FREE') ? {display: 'none'} : {}}>
							<Grid container spacing={2}>
								<Grid item md={4} sm={12} xs={12} className={classes.paymentCheckboxWrapperMedia}>
									<Grid container>
										<Grid item xs={12}>
											{paymentMethods.map((p) => (
												<div key={p.billingMethodId + p.name} className={clsx(classes.paymentWrapper, {[classes.paymentActive]: (p.name === formFields.selectedPaymentMethod)})}>
													<div className={clsx({[classes.paymentWrapperOverlay]: (createPaymentLoading || createPaypalPaymentIsLoading)})}></div>
													<div className={classes.paymentTitleContainer} onClick={() => this.handleChangePaymentMethod(p.name)}>
														<span className={classes.paymentTitleCircle}></span>
														<span className={clsx(classes.paymentTitle, ((p.name === REACT_APP_CREDIT_CARD) ? classes.paymentIconCC : (p.name === REACT_APP_PAYPAL_FLEXIBLE) ? classes.paymentIconPayPal : (p.name === REACT_APP_SEPA_DIRECT_DEBIT) ? classes.paymentIconSepa : (p.name === REACT_APP_IDEAL) ? classes.paymentIconIdeal : (p.name === REACT_APP_COINBASE) ? classes.paymentIconCoinbase : (p.name === REACT_APP_COINBILL) ? classes.paymentIconCoinbill : (p.name.indexOf(REACT_APP_PAYMENTWALL) > -1) ? clsx(classes.paymentIconPaywall, classes.pwm) : (p.name.indexOf(REACT_APP_SAVED_PAYMENT) > -1) ? classes.savedPaymentMethod : null))}>
															<span className={classes.pTitle}>{t(p.displayName)}</span>
															<span className={classes.paymentIcon}>
																{(p.name.indexOf(REACT_APP_PAYMENTWALL) > -1) ?
																	<>
																		{p.paymentWallMethods?.slice(0, 3).map(pm => (
																			<span key={pm.name} className={classes.paymentWallMethodItem}>
																				<span className={clsx(classes[(pm.name || '').replace('paymentwall', 'pw').replace('-test', '')], 'pw-icon')}></span>
																			</span>
																		))}
																		{p.paymentWallMethods?.length > 3 ? <span className={classes.pwMore}>and more!</span> : null}
																	</>: null
																}
																{(p.name.indexOf(REACT_APP_SAVED_PAYMENT) > -1) ?
																	<span className={classes.savedPaymentWrapper}>
																		{(p?.billingMethodObject?.name || '').indexOf('paypal') > -1 ? 
																			<span className={clsx(classes.savedPaymentIcon, classes.paypal)}></span>: 
																			(p?.billingMethodObject?.name || '').indexOf('sdd') > -1 ? 
																				<span className={clsx(classes.savedPaymentIcon, classes.sepa)}></span>:
																				<>
																					<span className={clsx(classes.savedPaymentIcon, classes[(p?.metaData?.creditcard_type || '')])}></span>
																					<span>{p?.metaData?.creditcard_binBrand} ending in {p?.metaData?.creditcard_last4}</span>
																				</>
																		}
																	</span> : null
																}

																{(p.name.indexOf(REACT_APP_COINBILL) > -1) ?
																	<>
																		{(cryptoLogos || []).map(cb => (
																			cb.iconUrl ? 
																				<span key={cb.name} className={classes.coinbillMethodItem}>
																					<span 
																						className={clsx(classes[('coinbill' + cb.name || '')], 'cb-icon')} 
																						style={{backgroundImage: `url(${(REACT_APP_COINBILL_URL + cb.iconUrl)})`}}>
																					</span>
																				</span> : null
																		))}
																	</>: null
																}
															</span>
														</span>
													</div>
												</div>))
											}
											<Logos country={country}/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item md={8} sm={12} xs={12}>
									<Grid container>
										<Grid item xs={12} classes={{root: classes.paymentBlock}}>
											{paymentMethods.map((p) => (
												<div key={p.billingMethodId + p.name} className={clsx(classes.paymentWrapper, classes.paymentWrapperMedia, {[classes.paymentActive]: (p.name === formFields.selectedPaymentMethod)})} style={(p.name === formFields.selectedPaymentMethod) ?  {} : {display: 'none'}}>
													<div className={clsx({[classes.paymentWrapperOverlay]: (createPaymentLoading || createPaypalPaymentIsLoading)})}></div>

													<div className={classes.paymentTitleContainer} onClick={() => this.handleChangePaymentMethod(p.name)}>
														<span className={classes.paymentTitleCircle}></span>
														<span className={clsx(classes.paymentTitle, ((p.name === REACT_APP_CREDIT_CARD) ? classes.paymentIconCC : (p.name === REACT_APP_PAYPAL_FLEXIBLE) ? classes.paymentIconPayPal : (p.name === REACT_APP_SEPA_DIRECT_DEBIT) ? classes.paymentIconSepa : (p.name === REACT_APP_IDEAL) ? classes.paymentIconIdeal : (p.name === REACT_APP_COINBASE) ? classes.paymentIconCoinbase : (p.name === REACT_APP_COINBILL) ? classes.paymentIconCoinbill : (p.name.indexOf(REACT_APP_PAYMENTWALL) > -1) ? clsx(classes.paymentIconPaywall, classes.pwm) : (p.name.indexOf(REACT_APP_SAVED_PAYMENT) > -1) ? classes.savedPaymentMethod : null))}>
															<span className={classes.pTitle}>{t(p.displayName)}</span>
															<span className={classes.paymentIcon}>
																{(p.name.indexOf(REACT_APP_PAYMENTWALL) > -1) ?
																	<>
																		{p.paymentWallMethods?.slice(0, 3).map(pm => (
																			<span key={pm.name} className={classes.paymentWallMethodItem}>
																				<span className={clsx(classes[(pm.name || '').replace('paymentwall', 'pw').replace('-test', '')], 'pw-icon')}></span>
																			</span>
																		))}
																		{p.paymentWallMethods?.length > 3 ? <span className={classes.pwMore}>and more!</span> : null}
																	</>: null
																}
																{(p.name.indexOf(REACT_APP_SAVED_PAYMENT) > -1) ?
																	<span className={classes.savedPaymentWrapper}>
																		{(p?.billingMethodObject?.name || '').indexOf('paypal') > -1 ? 
																			<span className={clsx(classes.savedPaymentIcon, classes.paypal)}></span>: 
																			(p?.billingMethodObject?.name || '').indexOf('sdd') > -1 ? 
																				<span className={clsx(classes.savedPaymentIcon, classes.sepa)}></span>:
																				<>
																					<span className={clsx(classes.savedPaymentIcon, classes[(p?.metaData?.creditcard_type || '')])}></span>
																					<span>{p?.metaData?.creditcard_binBrand} ending in {p?.metaData?.creditcard_last4}</span>
																				</>
																		}
																	</span> : null
																}
																{(p.name.indexOf(REACT_APP_COINBILL) > -1) ?
																	<>
																		{(cryptoLogos || []).map(cb => (
																			cb.iconUrl ? 
																				<span key={cb.name} className={classes.coinbillMethodItem}>
																					<span 
																						className={clsx(classes[('coinbill' + cb.name || '')], 'cb-icon')} 
																						style={{backgroundImage: `url(${(REACT_APP_COINBILL_URL + cb.iconUrl)})`}}>
																					</span>
																				</span> : null
																		))}
																	</>: null
																}
															</span>
														</span>
													</div>

													{p.name === REACT_APP_CREDIT_CARD ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<CreditCardFormV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																paymentJS={paymentJS}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createPaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_PAYPAL_FLEXIBLE ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<PayPalFormV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createPaypalPaymentIsLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_IDEAL ?
														<div className={clsx({[classes.paymentMethodContainer] : true, [classes.paymentMethodContainerShow] : (p.name === formFields.selectedPaymentMethod)})}>
															<IDealFormV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																paymentJS={paymentJS}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createIdealPaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																iDealBankList={iDealBankList}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_SEPA_DIRECT_DEBIT ?
														<div className={clsx({[classes.paymentMethodContainer] : true, [classes.paymentMethodContainerShow] : (p.name === formFields.selectedPaymentMethod)})}>
															<SepaDirectDebitFormV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																paymentJS={paymentJS}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createSepaPaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																country={country}
																allowedCountries={allowedCountries}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onChangeCountry={this.handleChangeCountry}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_COINBASE ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<CoinbaseV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createCoinbasePaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_COINBILL ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<CoinbillV2
																mbg={p.is_mbg}
																isApplicableInTheCountry={p.is_applicable_in_the_country}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createCoinbillPaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name.indexOf(REACT_APP_PAYMENTWALL) > -1 ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<PaymentWallV2
																mbg={p.is_mbg}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																paymentWallMethods={p.paymentWallMethods}
																btnDisabled={btnDisabled}
																btnProcessing={createPaymentWallLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

													{p.name === REACT_APP_SAVED_PAYMENT ?
														<div className={clsx(classes.paymentMethodContainer, {[classes.paymentMethodContainerShow]: (p.name === formFields.selectedPaymentMethod)})}>
															<SavedPaymentV2
																mbg={p.is_mbg}
																upgradeMode={upgradeMode}
																planConfig={planConfig}
																saving={saving}
																savingPercent={savingPercent}
																saving2Percent={saving2Percent}
																currency={currency}
																selectedPackage={selectedPackage}
																selectedPaymentMethod={formFields.selectedPaymentMethod || ''}
																formFields={formFields}
																paymentFields={paymentFields}
																btnDisabled={btnDisabled}
																btnProcessing={createSavedPaymentLoading}
																withPassword={withPassword}
																fieldsError={fieldsError}
																fieldsTouched={fieldsTouched}
																isCouponCodeFieldOpened={isCouponCodeFieldOpened}
																couponPrice={couponPrice}
																couponGracePeriod={couponGracePeriod}
																couponValidPlan={couponValidPlan}
																couponSavingPercent={couponSavingPercent}
																couponRenewalPrice={couponRenewalPrice}
																couponCode={couponCode}
																couponByCode={couponByCode}
																couponByCodeIsLoading={couponByCodeIsLoading}
																couponYearly2={couponYearly2}
																couponYearly={couponYearly}
																couponMonthly={couponMonthly}
																isProxyDetected={is_proxy_detected}
																onChangePaymentMethod={this.handleChangePaymentMethod}
																onOpenCouponCodeField={this.handleOpenCouponCodeField}
																onChangeCouponCodeField={this.handleChangeCouponCodeField}
																onSubmitCouponCode={this.handleSubmitCouponCode}
																onChangeFormFields={this.handleChangePaymentFields}
																onBlur={this.handleBlur}
																onInit={this.handlePaymentFieldsInit}
																onSubmit={this.handleSubmitForm}/>
														</div> : null
													}

												</div>))
											}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Fragment> : null
				}
			</Box>
		);
	}
}

const mapStateToProps = state => {
	return {
		securityDetails: state.securityCheck.securityDetails,
		paymentMethods: state.home.paymentMethods,
		allowedCountries: state.home.allowedCountries,
		paymentLoading: state.home.paymentMethodsLoading,
		createSavedPaymentLoading: state.home.createSavedPaymentLoading,
		createPaymentLoading: state.home.createPaymentLoading,
		createPaypalPaymentIsLoading: state.home.createPaypalPaymentIsLoading,
		createIdealPaymentLoading: state.home.createIdealPaymentLoading,
		createSepaPaymentLoading: state.home.createSepaPaymentLoading,
		createCoinbasePaymentLoading: state.home.createCoinbasePaymentLoading,
		createCoinbillPaymentLoading: state.home.createCoinbillPaymentLoading,
		createPaymentWallLoading: state.home.createPaymentWallLoading,
		freemiumProcessing: state.home.createFreemiumLoading || state.home.reactivateFreemiumLoading,
		couponByCode: state.home.couponByCode,
		couponByCodeIsLoading: state.home.couponByCodeIsLoading,
		iDealBankList: state.home.iDealBankList,
		couponMonthly: state.home.couponMonthly,
		couponYearly: state.home.coupon,
		couponYearly2: state.home.couponYearly2,
		freeRecaptchaChecked: state.home.freeRecaptchaChecked,
		sentryProps: state.home.sentryProps,
		cryptoLogos: state.home.cryptoLogos,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onCreateSavedPayment: dataToSend => dispatch(createSavedPayment(dataToSend)),
		onCreatePayment: dataToSend => dispatch(createPayment(dataToSend)),
		onCreateIdealPayment: dataToSend => dispatch(createIdealPayment(dataToSend)),
		onCreateSepaPayment: dataToSend => dispatch(createSepaPayment(dataToSend)),
		onCreateCoinbasePayment: dataToSend => dispatch(createCoinbasePayment(dataToSend)),
		onCreateCoinbillPayment: dataToSend => dispatch(createCoinbillPayment(dataToSend)),
		onCreatePaymentWall: dataToSend => dispatch(createPaymentWall(dataToSend)),
		onCreatePaypalPayment: dataToSend => dispatch(createPaypalPayment(dataToSend)),
		onCreateFreemium: dataToSend => dispatch(createFreemium(dataToSend)),
		onProcessTokenize: () => dispatch(processTokenize()),
		onCheckEmail: email => dispatch(checkEmail(email)),
		onCheckOrder: orderId => dispatch(checkOrder(orderId)),
		onUserAuth: data => dispatch(userAuth(data)),
		onChangeCurrency: (current_currency) => dispatch(changeCurrency(current_currency)),
		onChangeCountry: country => dispatch(changeCountry(country)),
		onGetIP: (selectedPaymentMethod) => dispatch(getIP(selectedPaymentMethod)),
		onSendAbandonEmailRequest: dataToSend => dispatch(sendAbandonEmailRequest(dataToSend)),
		onGetCouponByCode: (coupon, plan, addons, email) => dispatch(getCouponByCode(coupon, plan, addons, email)),
		onResetCouponByCode: dataToSend => dispatch(resetCouponByCode(dataToSend)),
		onGetPaymentMethods: (dataToSend) => dispatch(getPaymentMethods(dataToSend)),
		onGetCurrency: (countryCode, savedCurrency) => dispatch(getCurrency(countryCode, savedCurrency)),
		onCaptchaIsProcessing: (paymentType, loading) => dispatch(captchaIsProcessing(paymentType, loading)),
		onUpdateQueryParams: (queryParams) => dispatch(updateQueryParams(queryParams)),
		onUpdateSentryProps: sentryProps => dispatch(updateSentryProps(sentryProps)),
		onReactivateFreemium: dataToSend => dispatch(reactivateFreemium(dataToSend)),
	};
};

PaymentSectionV2.propTypes = {
	i18n: PropTypes.object,
	packages: PropTypes.array,
	upgradeMode: PropTypes.bool,
	planConfig: PropTypes.object,
	queryParams: PropTypes.object,
	classes: PropTypes.object,
	paymentJS: PropTypes.object,
	securityDetails: PropTypes.object,
	currency: PropTypes.string,
	savingPercent: PropTypes.string,
	saving2Percent: PropTypes.string,
	saving: PropTypes.object,
	yearlyPackage: PropTypes.object,
	yearly2Package: PropTypes.object,
	selectedPackage: PropTypes.object,
	paymentMethods: PropTypes.array,
	paymentLoading: PropTypes.bool,
	createSavedPaymentLoading: PropTypes.bool,
	createPaymentLoading: PropTypes.bool,
	createPaypalPaymentIsLoading: PropTypes.bool,
	createIdealPaymentLoading: PropTypes.bool,
	createSepaPaymentLoading: PropTypes.bool,
	createCoinbasePaymentLoading: PropTypes.bool,
	createCoinbillPaymentLoading: PropTypes.bool,
	createPaymentWallLoading: PropTypes.bool,
	freemiumProcessing: PropTypes.bool,
	enableAbandonmentModal: PropTypes.bool,
	couponByCode: PropTypes.object,
	couponMonthly: PropTypes.object,
	couponYearly: PropTypes.object,
	couponYearly2: PropTypes.object,
	couponByCodeIsLoading: PropTypes.bool,
	couponPrice: PropTypes.string,
	couponGracePeriod: PropTypes.number,
	couponValidPlan: PropTypes.string,
	couponSavingPercent: PropTypes.string,
	couponRenewalPrice: PropTypes.number,
	iDealBankList: PropTypes.array,
	freeRecaptchaChecked: PropTypes.bool,
	allowedCountries: PropTypes.array,
	sentryProps: PropTypes.object,
	cryptoLogos: PropTypes.array,
	onCheckEmail: PropTypes.func,
	onUserAuth: PropTypes.func,
	onProcessTokenize: PropTypes.func,
	onCreateSavedPayment: PropTypes.func,
	onCreatePayment: PropTypes.func,
	onCreateIdealPayment: PropTypes.func,
	onCreateSepaPayment: PropTypes.func,
	onCreateCoinbasePayment: PropTypes.func,
	onCreateCoinbillPayment: PropTypes.func,
	onCreatePaymentWall: PropTypes.func,
	onCreatePaypalPayment: PropTypes.func,
	onCreateFreemium: PropTypes.func,
	onCheckOrder: PropTypes.func,
	onChangeCurrency: PropTypes.func,
	onChangeCountry: PropTypes.func,
	onGetIP: PropTypes.func,
	onOpenAbandonmentModal: PropTypes.func,
	onSendAbandonEmailRequest: PropTypes.func,
	onResetCouponByCode: PropTypes.func,
	onGetCouponByCode: PropTypes.func,
	handleSelectPackage: PropTypes.func,
	handleScrollToPaymentSection: PropTypes.func,
	onGetPaymentMethods: PropTypes.func,
	onGetCurrency: PropTypes.func,
	navigate: PropTypes.func,
	t: PropTypes.func,
	onCaptchaIsProcessing: PropTypes.func,
	onUpdateQueryParams: PropTypes.func,
	onUpdateSentryProps: PropTypes.func,
	onReactivateFreemium: PropTypes.func,
};

const combainedStyles = utils.combineStyles(useStyles, paymentWallIconStyles);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combainedStyles)(withNamespaces()(PaymentSectionV2)));